import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./redux/store/store";
import { BrowserRouter } from 'react-router-dom'

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ErrorBoundary } from "react-error-boundary";

import "./index.scss";

const persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById('root'));


function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return (
    <div role="alert">
      <p>Algo salio mal, debemos reiniciar, por favor comparta esta pantalla con su representante</p>
      <button onClick={resetErrorBoundary}>Try again</button>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}



root.render(
  //https://www.npmjs.com/package/react-error-boundary
  <ErrorBoundary
    fallbackRender={fallbackRender}
    onReset={(details) => {
      localStorage.removeItem("persist:root");
      localStorage.clear();
      window.location.reload();
    }}>
    <React.StrictMode>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <Provider store={store}>
            <App />
          </Provider>
        </PersistGate>
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//Para crear la PWA
//import { register as registerServiceWorker } from './serviceWorkerRegistration';
//registerServiceWorker();
