import { Col, Dropdown, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import apiFc from "../../services/APIFc";
import { useDispatch, useSelector } from "react-redux";
import { decryptData } from "../../functions/cryptoUtils";
import CardGrupo from "../Card Grupos/CardGrupo";
import { AlertDialogInterface } from "./Interfaces";
import { setRubros } from "../../redux/actions/principal";
import ExtraLargeModal from "./ExtraLargeModal";


export default function ProductosDestacadosRubro() {
    const rubros = useSelector((store: any) => decryptData(store.principalreducer.rubros) || []);
    const [rubroSeleccionado, setRubroSeleccionado] = useState("")
    const [surgerenciaRubroArticuloGrupo, setSurgerenciaRubroArticuloGrupo] = useState([])
    const [surgerenciaRubro, setSurgerenciaRubro] = useState([])
    const [selectedGroup, setSelectedGroup] = useState<any>({});
    const user = useSelector((store: any) => decryptData(store.authreducer.user));
    const [articulosGrupo, setArticulosGrupo] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch()
    const [showDetalle, setShowDetalle] = useState(false);
    const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
      id: "1",
      visible: false,
      severity: 'success', // severity="error" / "warning" / "info" / "success"
      timeOut: 2000, // severity="error" / "warning" / "info" / "success"
      cabecera: "Agregado!",
      texto: "el producto fue agregado.",
    });
    const handleOpenModal = (articulo: any) => {
      setSelectedGroup(articulo);
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
  
    };

    useEffect(() => {
      obtenerInformacionNecesaria();
    }, [])

    useEffect(() => {
      if (selectedGroup.articuloGrupoId > 0) {
        try {
          apiFc.get(`/articuloGrupos/${selectedGroup.articuloGrupoId}/articulos`).then(res => {
            setArticulosGrupo(res.data)
            setShowDetalle(true)
          })
        } catch (error) { }
      }
    }, [selectedGroup])

    function obtenerInformacionNecesaria() {
    if (rubros && rubros.length !== 0) {
      var index = Math.floor(Math.random() * ((rubros?.length - 1) - 0 + 1)) + 0
      setRubroSeleccionado(rubros[index].detalle)
      buscarSugerenciaRubro(rubros[index].id)
      buscarSugerenciaRubroArticuloGrupos(rubros[index].id)
    } else {
      apiFc.get(`/articuloRubros`).then(res => {
        dispatch(setRubros(res.data))
        var index = Math.floor(Math.random() * ((res.data.length - 1) - 0 + 1)) + 0
        setRubroSeleccionado(res.data[index].detalle)
        buscarSugerenciaRubro(res.data[index].id)
        buscarSugerenciaRubroArticuloGrupos(res.data[index].id)
      })
    }}
    


    function buscarSugerenciaRubroArticuloGrupos(id: number) {
        apiFc.get(`/web/articulos/rubros/${id}/articulogrupos?page=0&rows=10`).then(res => {
          setSurgerenciaRubroArticuloGrupo(res.data.content)
        })
      }
      function buscarSugerenciaRubro(id: number) {
        if (id && id !== null)
            apiFc.get(`/web/principal/producto/sugerencias?rubroId=${id}`).then(res => {
            setSurgerenciaRubro(res.data);
          })
      }

      function imprimirGrupo() {
        const categories: any = {};
        surgerenciaRubro.forEach((article: any) => {
          const { articuloGrupoId } = article.articuloGrupoId;
          if (!categories[articuloGrupoId]) {
            categories[articuloGrupoId] = [];
          }
          categories[articuloGrupoId].push(article);
        });
      }

      const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  

      const itemsToShow = windowWidth < 968 ? 8 : 6
      ;
      useEffect(() => {
        // buscarSugerenciaRubro
      }, [surgerenciaRubro]);


  return (
    <div >
          <ExtraLargeModal
        show={showModal}
        handleClose={handleCloseModal}
        selectedGroup={selectedGroup} 
        alertDialog={alertDialog}
        // setAlertDialog={setAlertDialog}
        windowWidth={windowWidth}
      />
        <div className="mg-t-10 wd-100p">
        {rubros && rubros?.length > 0 && (
          <>
            <div className='mg-b-10 d-flex align-items-center mg-t-30'>
              <div className=''>
                <h1 className=" display-6 tx-20 tx-bold"><strong>Productos destacados del rubro:</strong></h1>
              </div>
              <Dropdown className="ms-2 catalogo-dropdown dropdown">
                <Dropdown.Toggle
                  variant=""
                  aria-expanded="false"
                  aria-haspopup="true"
                  className="btn ripple btn-primary "
                  data-bs-toggle="dropdown"
                  id="dropdownMenuButton1"
                  type="button"
                >
                  {rubroSeleccionado}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{ margin: "0px" }}
                  className="dropdown-menu btn-txt-font dropdown"
                >
                  {rubros?.map((rubro: any) => (
                    <Dropdown.Item
                      className="dropdown-item"
                      key={rubro.id}
                      onClick={() => {
                        setRubroSeleccionado(rubro.detalle);
                        buscarSugerenciaRubroArticuloGrupos(rubro.id);
                      }}
                    >
                      {rubro.detalle}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Row>
              {surgerenciaRubroArticuloGrupo.map((articuloGrupo: any, i) => {
                return i < itemsToShow ? (
                  <Col xs={6} sm={3} xl={2} xxl={2} lg={3} md={3} key={articuloGrupo.id}>
                    <CardGrupo articuloGrupo={articuloGrupo} onClick={() => {
                      setSelectedGroup(articuloGrupo);
                      handleOpenModal(articuloGrupo);
                    }} />
                  </Col>
                ) : (
                  <></>
                );
              })}
            </Row>
            {imprimirGrupo()}
          </>
        )}
      </div>
    </div>
  )
}

