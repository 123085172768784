import React from "react";
import { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col, Spinner, Table } from "react-bootstrap";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import apiFc from "../../services/APIFc";
import { AlertDialogInterface } from "./Interfaces";
import { useForm, SubmitHandler } from "react-hook-form";



type ErrorPedido = {
    pedidoErrorNroMovVenta: string;
    errorTipo: { pedidoErrorTipoID: string };
    pedidoErrorObservacionCarga: string;
    pedidoErrorList?: any
    pedidoErrores: { pedidoErrorID: string };
};

export default function SeguimientoError(props: any) {
    const [showDetalleError, setShowDetalleError] = useState<boolean>(props.visible);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [errorTipo, setErrorTipo] = useState([]);
    const [pedidoErrores, setPedidoErrores] = useState<any>();
    //Administrador de las tabs
    const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
        id: "1",
        visible: false,
        severity: 'success', // severity="error" / "warning" / "info" / "success"
        timeOut: 2000, // severity="error" / "warning" / "info" / "success"
        cabecera: "Enviado!",
        texto: "su formulario fue enviado y esta siendo revisado.",
    });
    useEffect(() => {
    }, [alertDialog]);
    useEffect(() => {
    }, [pedidoErrores]);

    useEffect(() => {
        apiFc.get(`/app/vendedor/sincro/datos/pedidoErrorTipo?hash=control_ip$5`)
            .then(res => {
                setErrorTipo(res.data)
            }).catch(err => {
                setErrorTipo([])
            });
    }, []);

    useEffect(() => {
        apiFc.get(`/pedidoErrores/${props.pedidoErrorId}`)
            .then(res => {
                setPedidoErrores(res.data)
            }).catch(err => {
                setPedidoErrores([])
            });
    }, []);

    useEffect(() => {
        if (showDetalleError) {
            setFullscreen("true")
        } else {
            props.onHandleShow();
        }
    }, [showDetalleError])



    useEffect(() => {
    }, [errorTipo]);

    useEffect(() => {

    }, [refresh]);



    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<ErrorPedido>();

    function Mensaje() {
        const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
            props,
            ref,
        ) {
            return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
        });

        const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            setAlertDialog({ ...alertDialog, visible: false });
        };
        return (
            <Snackbar
                open={alertDialog.visible}
                autoHideDuration={alertDialog.timeOut}
                onClose={handleClose}
                //Aca decido si es mobile muestro arriba el cartelito, sino abajo
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose}
                    severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
                    sx={{ width: '100%' }}>
                    <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
                </Alert>
            </Snackbar>
        )
    }

    const onSubmit: SubmitHandler<ErrorPedido> = async (data) => {
        // async request which may result error
        try {
            await apiFc.post(`/pedidoErrores/web`, JSON.stringify(data))
                .then(res => {
                  
                }).catch(res => {
                    setAlertDialog({ ...alertDialog, visible: true, severity: 'warning', cabecera: "Error conexion", texto: " intente luego" });
                });
        } catch (e) {
          
        }

    };

    let [fullScreen, setFullscreen] = useState("true");

    useEffect(() => {

    }, [fullScreen])

    function handlePedidoErrorTipoChange(value: string): void {
        pedidoErrores.map((errotTip: any) => {
            if (errotTip.pedidoErrorTipoID == value) {
                setValue("pedidoErrores", { pedidoErrorID: value })
                setRefresh(!refresh)
            }
        })
    }

    const shouldShowInput = props.pedidoErrorList === 0;




    function renderCuerpo(): any {
        return (
            <React.Fragment>
                {alertDialog.visible && <Mensaje ></Mensaje>}
                <div className="card-sigin modal-space-fix">
                    {/* <!-- Demo content--> */}
                    <Row className="row row-sm ">
                        <Col>
                            <Form >
                                <Form.Group >
                                    <Form.Label>Error ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="text-area-color"
                                        value={pedidoErrores.pedidoErrorID}
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label>Numero Pedido (NMV)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="text-area-color"
                                        value={pedidoErrores.pedidoErrorNroMovVenta}
                                        readOnly
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col >
                            <Form>
                                <Form.Group >
                                    <Form.Label>Pedido error tipo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="text-area-color"
                                        value={pedidoErrores.pedidoErrorTipoID?.pedidoErrorTipoDetalle}
                                        readOnly
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group >
                                <Form.Label>Observacion carga</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    className="text-area-color"
                                    value={pedidoErrores.pedidoErrorObservacionCarga}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="scrolleable-table">
                                <Form.Label>Seguimientos</Form.Label>
                                <Table className="table table-bordered mb-0 text-nowrap ">
                                    <thead>
                                        <tr>
                                            <th className="wd-10p">Fecha</th>
                                            <th className="wd-50p">Detalle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pedidoErrores.pedidoErrorRenglonList?.map((pedidoError: any) => (
                                            <tr>
                                                <td>{` ${pedidoError.pedidoErrorRengFecha && new Date(pedidoError.pedidoErrorRengFecha).toLocaleDateString([])}`}</td>
                                                <td>{` ${pedidoError.pedidoErrorRengDetalle} `}</td>
                                            </tr>
                                        ))
                                        }
                                        {pedidoErrores.pedidoErrorRenglonList?.map((pedidoError: any) => (
                                            <tr>
                                                <td>{` ${pedidoError.pedidoErrorRengFecha && new Date(pedidoError.pedidoErrorRengFecha).toLocaleDateString([])}`}</td>
                                                <td>{` ${pedidoError.pedidoErrorRengDetalle} `}</td>
                                            </tr>
                                        ))
                                        }
                                        {pedidoErrores.pedidoErrorRenglonList?.map((pedidoError: any) => (
                                            <tr>
                                                <td>{` ${pedidoError.pedidoErrorRengFecha && new Date(pedidoError.pedidoErrorRengFecha).toLocaleDateString([])}`}</td>
                                                <td>{` ${pedidoError.pedidoErrorRengDetalle} `}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </Form.Group>
                            <Form.Group >
                                <Form.Label>Observacion fin</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    className="text-area-color"
                                    value={pedidoErrores.pedidoErrorUsuarioFin}
                                    readOnly
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }



    return (
        <Modal className="wd-80p  m-auto" dialogClassName="modal-90w" show={showDetalleError} onHide={() => setShowDetalleError(false)}>
            <Modal.Header>
                <Modal.Title>Seguimiento</Modal.Title>
                <span className="d-flex ms-auto" onClick={() => setShowDetalleError(false)}><i className='fe fe-x ms-auto' ></i></span>
            </Modal.Header>
            <Modal.Body >
                {pedidoErrores ?
                    renderCuerpo()
                    : <div className="text-center mg-b-20 ">
                        <Spinner
                            animation="border"
                            className="spinner-border "
                            role="status"
                        >
                            <span className="sr-only">Cargando...</span>
                        </Spinner>
                    </div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDetalleError(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}