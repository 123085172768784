import React, { useEffect, useState } from 'react'
import CardGrupo from '../../Card Grupos/CardGrupo';
import FcCarousel from '../../FcCarousel/FcCarousel';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Col, Row, Spinner, Breadcrumb, Dropdown } from "react-bootstrap";
import Snackbar from '@mui/material/Snackbar';
import apiFc from '../../../services/APIFc';
import { decryptData } from '../../../functions/cryptoUtils';
import MarcaLogo from '../../MarcaLogo/MarcaLogo';
import CardLinea from '../../CardLinea/CardLinea';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExtraLargeModal from '../../common/ExtraLargeModal';
import Select from 'react-select';


const CatalogoRubro = () => {
  //parametros URL
  const [queryParameters] = useSearchParams("rubroId");
  //Redux
  const rubros = useSelector((store: any) => decryptData(store.principalreducer.rubros) || []);
  const user = useSelector((store: any) => decryptData(store.authreducer.user));
  //para el map de la card grupo
  const [productoItemList, setProductoItemList] = useState([]);
  //rubro,linea y marca
  const [selectedRubroId, setSelectedRubroId] = useState(queryParameters.get("rubroId") ?? "");
  const [selectedLineaId, setSelectedLineaId] = useState(queryParameters.get("lineaId"));
  const [marcasDelRubro, setMarcasDelRubro] = useState([])
  //supongo para cards
  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const [articulosGrupo, setArticulosGrupo] = useState([]);
  //notificaciones de compra o error
  const [showDetalle, setShowDetalle] = useState(false);
  const [alertDialog, setAlertDialog] = useState({
    id: "1",
    visible: false,
    severity: 'success',
    timeOut: 2000,
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = (articulo: any) => {
    setSelectedGroup(articulo);
    setShowModal(true);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const rubroColors: Record<string, string> = {
    1: '#D4131E',
    2: '#FF9100',
    3: '#FC4783',
    4: '#59087A',
    5: '#005DD1',
    6: '#009B0B'
  };

  useEffect(() => {
    fetchData(selectedRubroId, selectedLineaId);
    fetchMarcasDelRubro(selectedRubroId)

  }, [selectedRubroId]);

  useEffect(() => {
    fetchData(selectedRubroId, selectedLineaId);


  }, [selectedLineaId]);

  useEffect(() => {
    //cuando llegan nuevos productos o nuevas marcas, redibujo la pagina solamente
  }, [productoItemList, marcasDelRubro]);

  useEffect(() => {
    if (selectedGroup.articuloGrupoId > 0) {
      try {
        apiFc.get(`/articuloGrupos/${selectedGroup.articuloGrupoId}/articulos`).then(res => {
          setArticulosGrupo(res.data)
          setShowDetalle(true)
        })
      } catch (error) { }
    }
  }, [selectedGroup])


  useEffect(() => {
    const rubroId = queryParameters.get("rubroId");
    const lineaId = queryParameters.get("lineaId");
    setSelectedRubroId(rubroId ?? "");
    setSelectedLineaId(lineaId);
    fetchData(rubroId, lineaId);
    fetchMarcasDelRubro(rubroId);
  }, [queryParameters]);


  const fetchData = (rubroId?: any, lineaId?: any) => {
    if (lineaId !== null) {
      apiFc.get(`/web/articulos/rubros/lineas/${lineaId}/articulogrupos?page=0&rows=1000`)
        .then((res) => {
          setProductoItemList(res.data.content);
        });
      return;
    }
    if (rubroId !== null) {
      apiFc.get(`/web/articulos/rubros/${rubroId}/articulogrupos?page=0&rows=1000`)
        .then(res => {
          setProductoItemList(res.data.content);
        })
        .catch(error => {
          console.error("Error al cargar los rubros", error);
        });
    }
  };


  const fetchMarcasDelRubro = (rubroId?: any) => {
    if (rubroId !== null) {
      apiFc.get(`/web/articulos/marcas/rubro?id=${rubroId}`)
        .then((res) => {
          setMarcasDelRubro(res.data);
        }).catch(error => {
          console.error("Error al cargar los rubros", error);
        });
    }
  };

  // function Mensaje(props: any) {
  //   const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  //     props,
  //     ref,
  //   ) {
  //     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  //   });
  //   const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
  //     if (reason === 'clickaway') {
  //       return;
  //     }
  //     setAlertDialog({ ...alertDialog, visible: false });
  //   };
  //   return (
  //     <Snackbar
  //       open={alertDialog.visible}
  //       autoHideDuration={alertDialog.timeOut}
  //       onClose={handleClose}
  //       //Aca decido si es mobile muestro arriba el cartelito, sino abajo
  //       anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
  //       <Alert onClose={handleClose}
  //         severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
  //         sx={{ width: '100%' }}>
  //         <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
  //       </Alert>
  //     </Snackbar>
  //   )
  // }

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedGroup({});
  };

  const items = rubros
    .filter((rubro: any) => rubro.id === selectedRubroId)
    .flatMap((rubro: any) =>
      rubro.articuloRubroLineaList.map((linea: any) => ({
        image: linea.image,
        title: linea.title,
        description: linea.description,
      }))
    );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 550);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 550);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const rubroOptions = rubros.map((rubro: any) => ({
    value: rubro.id,
    label: rubro.detalle
  }));

  const handleRubroChange = (selectedOption: any) => {
    setSelectedRubroId(selectedOption ? selectedOption.value.toString() : null);
    setSelectedLineaId(null);
  };

  // const breadcrumbContent = (
  //   <>
  //     <Breadcrumb.Item className='tx-20'>Catálogo</Breadcrumb.Item>
  //     <Breadcrumb.Item className='tx-20'>
  //       <Dropdown className="btn-group">
  //         {rubros.find((rubro:any) => rubro.id === Number(selectedRubroId))?.detalle || 'Rubro'}
  //         <Dropdown.Toggle variant="" type="button">
  //           <span className="caret">
  //             <KeyboardArrowDownIcon />
  //           </span>
  //         </Dropdown.Toggle>
  //         <Dropdown.Menu style={{ margin: '0px' }} className="dropdown-menu tx-13">
  //           {rubros.map((rubro:any) => (
  //             <Dropdown.Item
  //               key={rubro.id}
  //               onClick={() => {
  //                 setSelectedRubroId(rubro.id);
  //                 setSelectedLineaId(null);
  //               }}
  //               className={selectedRubroId === rubro.id ? 'active' : ''}
  //             >
  //               {rubro.detalle}
  //             </Dropdown.Item>
  //           ))}
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     </Breadcrumb.Item>
  //     {selectedLineaId && (
  //       <Breadcrumb.Item className='tx-20'>
  //         <Dropdown className="btn-group">
  //           {rubros
  //             .find((rubro:any) => rubro.id === Number(selectedRubroId))
  //             ?.articuloRubroLineaList.find((linea:any) => linea.id === Number(selectedLineaId))
  //             ?.detalle || 'Línea'}
  //           <Dropdown.Toggle variant="" type="button">
  //             <span className="caret">
  //               <KeyboardArrowDownIcon />
  //             </span>
  //           </Dropdown.Toggle>
  //           <Dropdown.Menu style={{ margin: '0px' }} className="dropdown-menu tx-13">
  //             {rubros
  //               .find((rubro:any) => rubro.id === selectedRubroId)
  //               ?.articuloRubroLineaList.map((linea:any) => (
  //                 <Dropdown.Item
  //                   key={linea.id}
  //                   onClick={() => setSelectedLineaId(linea.id)}
  //                   className={selectedLineaId === linea.id ? 'active' : ''}
  //                 >
  //                   {linea.detalle}
  //                 </Dropdown.Item>
  //               ))}
  //           </Dropdown.Menu>
  //         </Dropdown>
  //       </Breadcrumb.Item>
  //     )}
  //   </>
  // );


  const selectedRubro = rubros.find((rubro: any) => rubro.id.toString() === selectedRubroId);

  const breadcrumbContent = (
    <>
      {isMobile ? (
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            className="btn btn-secondary btn-block"
            style={{
              width: '100%',
              padding: '10px 20px',
              backgroundColor: selectedRubro ? rubroColors[selectedRubroId] : 'initial',
              color: 'white'
            }}
          >
            {selectedRubro ? selectedRubro?.detalle : 'Selecciona un rubro'}
            <i className="icon ion-ios-arrow-down tx-11 mg-l-3"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ margin: '0px', width: '100%' }}>
            <Dropdown.Item
              style={{
                padding: '10px 20px',
                fontWeight: 'bold',
              }}
              disabled
            >
              Otros rubros
            </Dropdown.Item>
            {rubros.map((rubro: any) => (
              <Dropdown.Item
                key={rubro.id}
                onClick={() => handleRubroChange({ value: rubro.id })}
                style={{
                  padding: '10px 20px',
                  backgroundColor: rubro.id.toString() === selectedRubroId ? rubroColors[rubro.id.toString()] : 'white',
                  color: rubro.id.toString() === selectedRubroId ? 'white' : 'black',
                }}
              >
                {rubro?.detalle}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div className="rubro-container wd-100p" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <div className="selected-rubro" style={{ flex: '0 1 auto', minWidth: '150px', display: 'flex', alignItems: 'center' }}>
            {selectedRubro && (
              <>
                {/* Botón del rubro */}
                <button
                  style={{
                    backgroundColor: rubroColors[selectedRubroId],
                    color: 'white',
                    margin: '5px',
                    border: 'none',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 'large',
                    minWidth: '150px', // Añadir un ancho mínimo para evitar desplazamientos
                  }}
                  className="active"
                >
                  {selectedRubro?.detalle}
                </button>

                {/* Separación por línea gris */}
                <div style={{ width: '2px', height: '40px', backgroundColor: '#d3d3d3', margin: '0 10px' }}></div>

                {/* Botón de línea seleccionada */}
                {selectedLineaId && (
                  <button
                    style={{
                      backgroundColor: 'white',
                      color: rubroColors[selectedRubroId],
                      margin: '5px',
                      border: `2px solid ${rubroColors[selectedRubroId]}`,
                      padding: '5px 15px',
                      cursor: 'pointer',
                      borderRadius: '10px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 'medium',
                      minWidth: '150px', // Asegurar ancho consistente
                    }}
                  >
                    {rubros
                      .filter((rubro: any) => rubro.id.toString() === selectedRubroId)
                      .flatMap((rubro: any) =>
                        rubro.articuloRubroLineaList.filter((linea: any) => linea.id === selectedLineaId).map((linea: any) => linea.detalle)
                      )}
                  </button>
                )}
              </>
            )}
          </div>

          {/* Contenedor de otros rubros */}
          <div className="remaining-rubros" style={{ flex: '1', marginLeft: 'auto', textAlign: 'center' }}>

            <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
              {rubros
                .filter((rubro: any) => rubro.id.toString() !== selectedRubroId)
                .map((rubro: any) => (
                  <button
                    key={rubro.id}
                    style={{
                      backgroundColor: rubroColors[rubro.id.toString()],
                      color: 'white',
                      margin: '5px',
                      border: 'none',
                      padding: '10px 20px',
                      cursor: 'pointer',
                      borderRadius: '4px',
                      flex: '0 1 auto',
                      minWidth: '150px', // Asegurar ancho mínimo
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                    onClick={() => handleRubroChange({ value: rubro.id })}
                  >
                    {rubro?.detalle}
                  </button>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
        </div>
        <ExtraLargeModal
          show={showModal}
          handleClose={handleCloseModal}
          selectedGroup={selectedGroup}
          alertDialog={alertDialog}
          // setAlertDialog={setAlertDialog}
          windowWidth={windowWidth}
        />
      </div>
      <div className="justify-content-center mt-2">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', gap: '5px' }}>
          {/* Texto "Está viendo" alineado a la izquierda */}
          <p style={{ fontWeight: 'bold', marginBottom: '0', marginRight: '20px' }} className="tx-20">
            Está viendo:
          </p>

          {/* Texto "Otros rubros" alineado a la derecha */}
          <p style={{ fontWeight: 'bold', marginBottom: '0', marginLeft: 'auto', textAlign: 'right' }} className="tx-20">
            Otros rubros
          </p>
        </div>
        <Breadcrumb>
          {isMobile ? <div>{breadcrumbContent}</div> : breadcrumbContent}
        </Breadcrumb>
      </div>
      {/* <!-- /breadcrumb --> */}
      <p style={{ fontWeight: 'bold', marginBottom: '10px' }} className="tx-20">Líneas del rubro: {selectedRubro?.detalle}</p>
      <Col>
        <Row>
          {(rubros && rubros.length > 0) ? (
            <FcCarousel items={
              rubros
                .filter((rubro: any) => rubro.id == selectedRubroId)
                .map((rubro: any) => (
                  rubro.articuloRubroLineaList.map((linea: any) => {
                    // console.log(linea)
                    return (
                      <Col xs={12} sm={12} xl={2} xxl={2} lg={2} md={2} className="justify-content-center" key={linea.id}>
                        <CardLinea
                          rubro={rubro}
                          linea={linea}
                          showRubroName={false} // controlar si se muestra el nombre del rubro para las tarjetas del catalogo
                          showLineaName={false}
                          showIcon={false} // controlar si se muestra el icono del rubro para las tarjetas del catalogo
                          onClick={() => {
                            setSelectedLineaId(linea.id)
                          }}
                        />
                      </Col>
                    )
                  })
                ))
            } />
          ) : (
            <p>No hay rubros disponibles.</p>
          )}
        </Row>

        {/* <Row>
          <div className='mg-b-10 mg-t-30'>
            <h1 className=" display-6 tx-20 tx-bold"><strong>Las mejores marcas del rubro</strong></h1>
          </div>
        </Row>
        {(marcasDelRubro && marcasDelRubro?.length) ? (
          <FcCarousel items={
            marcasDelRubro?.sort(() => 0.5 - Math.random()).slice(0, 8).map((marca: any) => (
              <Col xs={6} sm={6} xl={3} xxl={2} lg={4} md={4} className="mg-t-10" key={marca.id}>
                <MarcaLogo marca={marca} />
              </Col>
            ))
          } />
        ) : (
          <></>
        )} */}
        <div className="mg-b-10 mg-t-30">
          <h1 className=" display-6 tx-20 tx-bold">
            <strong>Articulos del rubro</strong>
          </h1>
        </div>
        <Row>
          {productoItemList.length === 0 ? (
            <div className="text-center mg-b-20 ">
              <Spinner animation="border" className="spinner-border " role="status">
                <span className="sr-only">Cargando...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {productoItemList.map((articuloGrupo: any, i) => (
                <Col xs={6} sm={3} xl={2} xxl={2} lg={3} md={3} key={i}>
                  <CardGrupo
                    articuloGrupo={articuloGrupo}
                    onClick={() => {
                      setSelectedGroup(articuloGrupo);
                      handleOpenModal(articuloGrupo);
                    }}
                  />
                </Col>
              ))}
            </>
          )}
        </Row>
      </Col>
    </div>
  );
};

export default CatalogoRubro