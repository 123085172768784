import { Select, useMediaQuery } from "@material-ui/core";
import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Modal, Row, Spinner, Tab, Badge, Tabs, Table, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import apiFc from "../../../../services/APIFc";
import { useSelector } from "react-redux";
import { decryptData } from "../../../../functions/cryptoUtils";

export default function DetallePedido(props: any) {
    const [showDetallePedido, setShowDetallePedido] = useState<boolean>(props.visible);
    const [cargandoDetallePedido, setCargandoDetallePedido] = useState(0); //0:cargando 1:Cargado 2:Pedido inexistente
    const [detallePedido, setDetallePedido] = useState<any>({});
    const transportesDisponibles = useSelector((store: any) => decryptData(store.pedidoreducer.transportesDisponibles));
    const transporteNombre = transportesDisponibles.find((transporte: any) => transporte.transporteID === detallePedido.transportePedidoID);
    const [cargandoRenglonesPedido, setCargandoRenglonesPedido] = useState(0); //0:cargando 1:Cargado 2:Pedido inexistente
    const [renglonesPedido, setRenglonesPedido] = useState<any>({});
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getClassByEstado = (estado: string) => {
        let baseClass = '';
        switch (estado) {
            case 'RECIBIDO CLIENTE':
                baseClass = 'fade alert fade show alert-dismissible alert-solid-success alert show';
                break;
            case 'CANCELADO':
                baseClass = 'fade alert fade show alert-dismissible alert-solid-danger alert show';
                break;
            case 'CANCELADO POR FALTANTE':
                baseClass = 'fade alert fade show alert-dismissible alert-solid-warning alert show';
                break;
            default:
                return '';
        }

        if (windowWidth < 1200) {
            if (estado === 'RECIBIDO CLIENTE' || estado === 'CANCELADO POR FALTANTE') {
                return `${baseClass} d-flex wd-80p`;
            }
        }

        return baseClass;
    };

    useEffect(() => {
        if (showDetallePedido) {
            setFullscreen("true")
            setCargandoDetallePedido(0)
            apiFc.get(`/web/pedido/${props.nroMovVenta}/dto`).then(res => {
                setDetallePedido(res.data);
                setCargandoRenglonesPedido(1)
            }).catch(error => {
                setRenglonesPedido(null);
                setCargandoRenglonesPedido(2)
            })
        } else {
            props.onHandleShow();
        }
    }, [showDetallePedido])

    function imagenEstadoPedido(idEstado: number): string {

        switch (idEstado) {
            case 1:
                return ("ENVIADO.png");
            case 2:
                return ("RECIBIDO.png");
            case 3:
                return ("DEMORADO.png");
            case 4:
                return ("PROCESANDO.png");
            case 5:
                return ("EMBALADO.png");
            case 6:
                return ("FACTURADO.png");
            case 10:
                return ("DESPACHADO.png");
            case 20 || 21 || 22 || -70 || -101 || -102:
                return ("CANCELADO.png");
            case 50:
                return ("ENTREGADO.png"); //<a href="https://www.flaticon.es/iconos-gratis/entrega" title="entrega iconos">Entrega iconos creados por Freepik - Flaticon</a>

            default: return ("PROCESANDO.png");
        }
    }


    let [fullScreen, setFullscreen] = useState("true");


    useEffect(() => {

    }, [fullScreen])

    const fullscreenmodalClose = () => setShowDetallePedido(false);

    function handleShow(breakpoint: any) {
        setFullscreen(breakpoint);
        setShowDetallePedido(true);
    }


    const [thumbsSwiper, setThumbsSwiper] = React.useState<any>(null);
    const [Data, setData] = useState<any>([]);
    const isMobile = useMediaQuery('(max-width:650px)');


    function renderCuerpo(): any {
        // if (cargandoDetallePedido == 0)
        //     return (
        //         <div className="text-center mg-b-20 ">
        //             <Spinner
        //                 animation="border"
        //                 className="spinner-border "
        //                 role="status"
        //             >
        //                 <span className="sr-only">Cargando cabecera...</span>
        //             </Spinner>
        //         </div>
        //     );

        if (cargandoRenglonesPedido == 0)
            return (
                <div className="text-center mg-b-20 ">
                    <Spinner
                        animation="border"
                        className="spinner-border "
                        role="status"
                    >
                        <span className="sr-only">Cargando renglones...</span>
                    </Spinner>
                </div>
            );
        else
            return (
                <>
                    <Row className="row row-sm" >
                        <Col >
                            <Card>
                                <Card.Body>
                                    <Row className="row row-sm ">
                                        <Col>
                                            <Row >
                                                <Col xxl={6} xl={6} lg={10} md={10} sm={10}>
                                                    <div className="product-carousel  border br-5">
                                                        <div id="Slider" className="carousel slide">
                                                            <img src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${detallePedido.PedidoGrupoId.PedidoGrupoImagenGXI}`}
                                                                key={detallePedido.PedidoCodigo}
                                                                alt=""
                                                                className="img-fluid mx-auto d-block"
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null; // prevents looping
                                                                    currentTarget.src = "https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png";
                                                                }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xxl={6} lg={6} md={12} className="details mt-4 mt-xl-0 mt-2">
                                            <h4 className="product-title mb-1">
                                                {detallePedido.PedidoDescripcion}
                                            </h4>
                                            <p className="text-muted tx-13 mb-1">
                                                {detallePedido.PedidoGrupoId.PedidoGrupoDescripcion}
                                            </p>
                                            <div className="rating mb-1">
                                                <div className="stars">
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star checked"></span>
                                                    <span className="fa fa-star text-muted"></span>
                                                </div>
                                                <span className="review-no">41 compras este mes</span>
                                            </div>
                                            <h6 className="price">
                                                Precio: <span className="h3 ms-2">{`$ ${detallePedido.PedidoPrecio}`}</span>
                                            </h6>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </>
            );
        if (cargandoDetallePedido == 2)
            return (
                <Alert
                    className={`alert mb-2 alert-danger`}
                    variant=""
                >
                    <Alert.Heading>
                        <h4 className="alert-heading">
                            <i className={`fe fe-alert-octagon me-2 tx-20`}></i>
                            Error cargando Pedido
                        </h4>
                    </Alert.Heading>
                    <p>
                        Sucedio un error cargando la informacion del Pedido
                    </p>
                    <hr />
                    <p className="mb-0">
                        Puede reintentar nuevamente en un momento.
                    </p>
                </Alert>
            );
    }


    const estado = detallePedido.pedidoEstado?.pedidoEstadoDescripcion || '';

    return (
        <Modal show={showDetallePedido} dialogClassName="modal-90w" onHide={() => setShowDetallePedido(false)}>
            <Modal.Header>
                <Modal.Title>Detalle Pedido</Modal.Title>
                <span className="d-flex ms-auto" onClick={() => setShowDetallePedido(false)}><i className='fe fe-x ms-auto' ></i></span>
            </Modal.Header>
            <Modal.Body>
                <div className="panel panel-primary tabs-style-2">
                    <div className=" tab-menu-heading">
                        <div className="tabs-menu1">
                        <Row className={`wd-100p mg-b-10 ${isMobile ? 'flex-column' : ''}`}>
            <Col>
                <p className="tx-15">
                    <strong className="tx-15 mg-r-5">Fecha Pedido:</strong> {new Date(detallePedido.pedidoFecha).toLocaleDateString('es-ES', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    })}
                </p>
                <p className="tx-15">
                    <strong className="tx-15 mg-r-5">NroMovVenta:</strong>{detallePedido.pedidoNroMovVta}
                </p>
            </Col>
            <Col className="mg-b-10">
                {detallePedido.pedidoFechaDespacho && (
                    <p className="tx-15">
                        <strong className="tx-15 mg-r-5">Fecha Despachado: </strong>
                        {new Date(detallePedido.pedidoFechaDespacho).toLocaleDateString('es-ES', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        })}
                    </p>
                )}
                <p className="tx-15">
                    <strong className="tx-15 mg-r-5">Transporte:</strong>  {transporteNombre ? transporteNombre.transporteNombre : 'Transporte no encontrado'}
                </p>
            </Col>
            <Col>
                <p className="tx-15">
                    <strong className="tx-15 mg-r-5">Estado pedido:</strong>
                    <img
                        src={require(`../../../../assets/img/pedido/${imagenEstadoPedido(detallePedido.pedidoEstado?.pedidoEstadoId)}`)}
                        alt=""
                        width="32"
                        height="32"
                        className="br-2"
                    />
                    <b className={getClassByEstado(estado)}>
                        {estado}
                    </b>
                </p>
                {detallePedido.pedidoFechaRecibido && (
                    <p className="tx-15">
                        <strong className="tx-15 mg-r-5">Fecha Recibido:</strong> {new Date(detallePedido.pedidoFechaRecibido).toLocaleDateString('es-ES', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        })}
                    </p>
                )}
            </Col>
        </Row>
                            <Card>
                                <Tabs
                                    defaultActiveKey="tRenglones"
                                    className=" panel-tabs main-nav-line "
                                >
                                    <Tab eventKey="tRenglones" title="Renglones" >
                                        {/* <!-- row opened --> */}
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <Card.Body>
                                                        {/* <!-- Shopping Cart--> */}
                                                        <div className="product-details table-responsive text-nowrap">
                                                            <Table className="table table-bordered table-hover mb-0 text-nowrap">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-start">Producto</th>
                                                                        <th className="w-150 text-center ">Cantidad</th>
                                                                        <th className="w-150 text-center ">Cumplido</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(cargandoRenglonesPedido === 1) ?
                                                                        <Fragment>
                                                                            {
                                                                                detallePedido.renglones?.map((item: any) => {
                                                                                    return (
                                                                                        <Fragment key={item.pedidoRenglonPK.pedidoNroRenglon}>
                                                                                            <tr >
                                                                                                <td
                                                                                                // open={open}
                                                                                                //onClick={handleClose}
                                                                                                >
                                                                                                    <div className="media">
                                                                                                        <div className="card-aside-img">
                                                                                                            <img src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${item.articuloImagen}`} className="h-60 w-60" alt="" />
                                                                                                        </div>
                                                                                                        <div className="media-body">
                                                                                                            <div className="card-item-desc mt-0">
                                                                                                                <h6 className="font-weight-semibold mt-0 text-uppercase">
                                                                                                                    {item.articuloDescripcion}
                                                                                                                </h6>
                                                                                                                <dl className="card-item-desc-1">
                                                                                                                    <dt className="mg-r-5">{`Codigo:       `}</dt>
                                                                                                                    <dd>{`       ${item.articuloCodigo}`}</dd>
                                                                                                                </dl>
                                                                                                                <dl className="card-item-desc-1">
                                                                                                                    <dt className="mg-r-5">{`U.v:     `}</dt>
                                                                                                                    <dd>{`   Paquete`}</dd>
                                                                                                                    {/* <dd>{item.colors}</dd> */}
                                                                                                                </dl>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="text-center text-lg text-medium font-weight-bold  tx-15">
                                                                                                    {(item.pedidoRenglonCantidad).toFixed(2)}
                                                                                                </td>
                                                                                                <td className="text-center text-lg text-medium font-weight-bold  tx-15">
                                                                                                    {(item.pedidoRenglonCantidadCumplida).toFixed(2)}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Fragment>
                                                                        : <></>
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                        {/* <!-- row closed --> */}
                                    </Tab>
                                    <Tab eventKey="tNovedades" title="Novedades">
                                        <div className="container justify-content-center">
                                            <ul className="notification">
                                                <li>
                                                    <div className="notification-time">
                                                        <span className="date">Friday</span>
                                                        <span className="time">02:31</span>
                                                    </div>
                                                    <div className="notification-icon ">
                                                        <Link to="#"></Link>
                                                    </div>
                                                    <div className="notification-body">
                                                        <div className="media mt-0">
                                                            <div className="main-img-user avatar-md main-avatar online me-3 shadow">
                                                                <img
                                                                    alt="avatar"
                                                                    className="rounded-circle badge "
                                                                    src={require("../../../../assets/img/faces/6.jpg")}
                                                                />
                                                            </div>
                                                            <div className="media-body">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="mt-0">
                                                                        <h5 className="mb-1 tx-15 font-weight-semibold text-dark">
                                                                            Descargado
                                                                        </h5>
                                                                        <p className="mb-0 tx-13 mb-0 text-muted">
                                                                            Pedido descargado a los servidores {' '}
                                                                            <Badge
                                                                                bg=""
                                                                                className=" badge-primary-transparent tx-12 font-weight-semibold text-dark ms-1 me-1"
                                                                            >
                                                                                locales
                                                                            </Badge>{' '}
                                                                            , esperando {' '}
                                                                            <Badge
                                                                                bg=""
                                                                                className=" badge-primary-transparent text-dark tx-12 font-weight-semibold ms-1 me-1"
                                                                            >
                                                                                derivacion
                                                                            </Badge>
                                                                        </p>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <Badge bg="" className="float-end badge notification-badge">
                                                                            <span className="tx-11 font-weight-semibold">
                                                                                24, oct 2021
                                                                            </span>
                                                                        </Badge>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Card>
                        </div>
                    </div>
                </div>
                {/* {renderCuerpo()} */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDetallePedido(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}