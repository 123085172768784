import { Dispatch } from "react"
import apiFc from "../../services/APIFc"
import axios from "axios"
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { decryptData, encryptData } from "../../functions/cryptoUtils";


export const setNovedades = (novedades: any) => {
    return {
        type: "SET_NOVEDADES",
        payload: encryptData(novedades)
    }
}
export const setMarcas = (marcas: any) => {
    return {
        type: "SET_MARCAS",
        payload: encryptData(marcas)
    }
}
export const setRubros = (rubros: any) => {
    return {
        type: "SET_RUBROS",
        payload: encryptData(rubros)
    }
}
