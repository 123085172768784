import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import APIFc from '../../../services/APIFc';
import { logIn,setLoginText} from '../../../redux/actions/auth';
import ReactFacebookPixel from 'react-facebook-pixel';
import * as Switcherdata from '../../../data/Switcherdata/Switcherdata';
import { decryptData } from '../../../functions/cryptoUtils';




const Login = () => {
  const [clienteCodigo, setClienteCodigo] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch()
  const loginText = useSelector((store: any) => decryptData(store.authreducer.loginText));
  const showLoginText = useSelector((store: any) => decryptData(store.authreducer.showText));


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // Verificar el código y la contraseña
    APIFc.post('/login', `{"clienteCodigo":${clienteCodigo}, "password":"${password}"}`)
      .then(res => {
        //Seteo el pixel de google, incluso si el cliente no esta habilitado, pero para trackearlo igualmente
        ReactFacebookPixel.trackCustom("login", `{"clienteCodigo":${clienteCodigo}}`);
        dispatch(logIn(res.data.bearer));
      }).catch(res => {
        dispatch(setLoginText("Error consultando al servidor, intente nuevamente en unos minutos."));
      });
  }

  useEffect(() => {}, [loginText])

  return (
    <React.Fragment>
      <div className="square-box"> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> </div>
      <div className="page bg-primary">
        <div className="page-single">
          <div className="container" style={{ marginTop: "89px" }} >
            <Col xl={5} lg={6} md={8} sm={8} xs={10} className="card-sigin-main mx-auto my-auto py-4 justify-content-center">
              <div className="card-sigin">
                {/* <!-- Demo content--> */}
                <div className="main-card-signin d-md-flex">
                  <div className="wd-100p">
                    <div className="d-flex mb-4">
                      <a href="#">
                        <img
                          src={require("../../../assets/img/logos/logo_favicon.png")}
                          className="sign-favicon ht-40"
                          alt="logo"
                        />
                      </a>
                    </div>
                    <div className="">
                      <div className="main-signup-header">
                        <h2>Bienvenido!</h2>
                        <h6 className="font-weight-semibold mb-4">
                          Ingrese sus credenciales
                        </h6>
                        <div className="panel panel-primary">
                          <div className=" tab-menu-heading mb-2 border-bottom-0">
                            <div className="tabs-menu1">
                              {loginText !== "" && <Alert variant="danger">{loginText}</Alert>}
                              <Form >
                                <Form.Group className="form-group">
                                  <Form.Label className=''>Número de cuenta</Form.Label>{" "}
                                  <Form.Control
                                    className="form-control"
                                    placeholder="Ingrese su numero de cuenta"
                                    name="email"
                                    type='number'
                                    value={clienteCodigo}
                                    onChange={e => setClienteCodigo(e.target.value)}
                                    required
                                  />
                                </Form.Group>
                                <Form.Group className="form-group">
                                  <Form.Label>Contraseña</Form.Label>{" "}
                                  <Form.Control
                                    className="form-control"
                                    placeholder="Ingresa tu contraseña"
                                    name="password"
                                    type='password'
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    required
                                  />
                                </Form.Group>
                                <Button
                                  variant=""
                                  type='submit'
                                  className="btn btn-primary btn-block"
                                  onClick={handleSubmit}
                                >
                                  Ingresar
                                </Button>
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div >
      </div>
    </React.Fragment>
  );
}

Login.propTypes = {};

Login.defaultProps = {};

export default Login;


