import { MutableRefObject, useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import apiFc from "../../services/APIFc";
import ReactFacebookPixel from 'react-facebook-pixel';
import { setPedidoActualRenglones, setPendientes } from "../../redux/actions/pedido";
import { AlertDialogInterface } from "./Interfaces";
import { decryptData } from "../../functions/cryptoUtils";

interface InputCarritoCompraProps {
    articulo: any;
    origen: string;
    className?: string;
    onEvent: (e: AlertDialogInterface) => any;
}

function InputCarritoCompra(props: InputCarritoCompraProps) {
    const pedidoActualRenglones = useSelector((store: any) => decryptData(store.pedidoreducer.pedidoActualRenglones));
    const [cantidadArticuloCheck, setCantidadArticuloCheck] = useState<number>(0)
    const dispatch = useDispatch();
    const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
        id: "1",
        visible: false,
        severity: 'success', // severity="error" / "warning" / "info" / "success"
        timeOut: 2000, // severity="error" / "warning" / "info" / "success"
        cabecera: "Agregado!",
        texto: "el producto fue agregado.",
    });

    useEffect(() => {
        if (alertDialog.visible) {
            props.onEvent(alertDialog);
        }
    }, [alertDialog])


    const cargarArticuloAPedido = (item: any, e: any) => {
        if (e.target['cantidad'].value == 0 || e.target['cantidad'].value == "0") {
            setAlertDialog({ ...alertDialog, visible: true, cabecera: "Debe elegir una cantidad", texto: ``, severity: "warning" })
            return
        }
        if (item.articuloCantidadMultiplo > 0 && (e.target['cantidad'].value % item.articuloCantidadMultiplo) !== 0) {
            setCantidadArticuloCheck(item.articuloCodigo)
            setAlertDialog({ ...alertDialog, visible: true, cabecera: `Error en producto, debe ser multiplo de ${item.articuloCantidadMultiplo} .`, texto: ``, severity: "warning" })
            return
        }
        if (item.articuloCantidadMaxSugerida > 0 && e.target['cantidad'].value > item.articuloCantidadMaxSugerida && cantidadArticuloCheck !== item.articuloCodigo) {
            setCantidadArticuloCheck(item.articuloCodigo)
            setAlertDialog({ ...alertDialog, visible: true, cabecera: "Supera la cantidad maxima sugerida. Quiere añadir de todas formas la cantidad ingresada?", texto: ``, severity: "warning" })
            return
        } else {
            setCantidadArticuloCheck(0)
        }
        apiFc.post(`/web/pedido/actual/renglon/${item.articuloCodigo}?accion=1&cantidad=${e.target['cantidad'].value}`, `{}`)
            .then(res => {
                apiFc.get(`/web/pedido/actual/renglon`)
                    .then(res => {
                        ReactFacebookPixel.trackCustom("agregaArticulo", `{"origen": "${props.origen}" ,"articulo":"${item.articuloCodigo}"}`);
                        dispatch(setPedidoActualRenglones(res.data))
                        setAlertDialog({ ...alertDialog, visible: true, cabecera: "Agregado con exito", texto: `El articulo ${item.articuloCodigo}`, severity: "success" })
                    }).catch(err => {
                        setAlertDialog({ ...alertDialog, visible: true, cabecera: "Error agregando articulo", texto: `El articulo ${item.articuloCodigo} no pudo ser agregado`, severity: "warning" })
                    });
                apiFc.get(`/web/pedido/pendientes`)
                    .then(res => {
                        dispatch(setPendientes(res.data))
                    }).catch(err => {
                        dispatch(setPendientes([]))
                    });
            }).catch(res => {
                setAlertDialog({ ...alertDialog, visible: true, cabecera: "Error conectando servidor", texto: `El articulo ${item.articuloCodigo} no pudo ser agregado`, severity: "warning" })
            });
    }


    function cantidadPedida(codArticulo: string): number {
        return (pedidoActualRenglones?.find((renglon: any) => renglon.articuloCodigo === codArticulo)?.pedidoRenglonCantidad || 0)
    }


    return (
        
        (props.articulo.articuloComercializacionTipo?.articuloComercializacionTipoId != 1) 
        && (props.articulo.articuloComercializacionTipo?.articuloComercializacionTipoId !=undefined)
            ?
            <p className={"badge bg-teal tx-12 font-weight-semibold text-primiary ms-1 me-1 wd-100p"}>
                {`${props.articulo.articuloComercializacionTipo?.articuloComercializacionTipoDetalle}`}
            </p>
            :
            <Form onSubmit={(e: any) => {
                e.preventDefault();
                cargarArticuloAPedido(props.articulo, e);
            }}>
                <InputGroup className={`input-group wd-100 ${props.className}`}  >
                    <Form.Control
                        id="cantidad"
                        className="form-control "
                        defaultValue={cantidadPedida(props.articulo.articuloCodigo)}
                        onFocus={(event) => event.target.select()}
                    />
                    <Button
                        variant=""
                        className="btn btn-primary br-ts-0 br-bs-0"
                        type="submit"
                    >
                        <i className="fa fa-check"></i>
                    </Button>
                </InputGroup>
            </Form>
    )
}

export default InputCarritoCompra;