import React from 'react';
import "./cardmarca.css";
import { useNavigate } from 'react-router-dom';
import LazyImage from '../../functions/LazyImage';
import {tamañoPortadaMarca } from '../../functions/Procedimientos';

const CardMarca = (props: any) => {
    const navigate = useNavigate();

    return (
        <div
            className="card-marca-container"
            onClick={() => navigate(
                window.location.href.includes("/principal") 
                    ? `/principal/catalogomarca?marcaId=${props.marca.id}` 
                    : `/catalogomarca?marcaId=${props.marca.id}`
            )}
        >
            <div className="card-marca-img-container">
            <LazyImage
    className=""
    key={props.marca.id}
    src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Marcas/PortadaMarcas/${props.marca.id}${tamañoPortadaMarca(window.innerWidth)}.jpg`}
    alt="Imagen 1"
    onError={(e: any) => {
        const imgElement = e.target;
        if (imgElement.src.includes("PortadaMarcas")) {
            imgElement.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Marcas/PortadaMarcas/${props.marca.id}.png`;
        } else {
            imgElement.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`;
        }
    }}
/>
            </div>
            <div className="logo-overlay">
                <LazyImage
                    className=""
                    key={`over${props.marca.id}`}
                    src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Marcas/${props.marca.id}.jpg`}
                    alt="Imagen 1"
                    onError={(e: any) => {
                        e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`;
                    }}
                />
            </div>
            <div className="marca-text-container">
                <div className="card-marca-title">
                    {props.marca.descripcion}
                </div>
            </div>
            <div className="products-container">
                <div className="products">
                    {props.marca?.articuloListSample?.map((articulo: any) => (
                        <a key={articulo.articuloCodigo} className="products-item">
                            <LazyImage
                                className=""
                                src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articulo.articuloImagenV2}`}
                                alt="Imagen de Artículo"
                                onError={(e: any) => {
                                    e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`;
                                }}
                            />
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CardMarca;
