import store from "../redux/store/store";


export const getImagenVendedor = (vendedorID: any): any => {
  try {
    return require(`../assets/img/vendedores/${vendedorID}.jpg`);
  } catch (error) {
    return require(`../assets/img/vendedores/0.jpg`);
  }
};

interface StockLevelProps {
  stock: number;
  stockMax: number;
  stockMin: number;
  onClick?: () => void;
}

export function StockLevel(props: StockLevelProps) {
  if (props.stock > props.stockMax * 2)
    return (<><i className="fas fa-battery-full"></i></>)
  if (props.stock > props.stockMax)
    return (<><i className="fas fa-battery-three-quarters"></i></>)
  if (props.stock < props.stockMax && props.stock > props.stockMin)
    return (<><i className="fas fa-battery-half"></i></>)
  if (props.stock > props.stockMin * 2 || props.stock > 0)
    return (<><i className="fas fa-battery-quarter"></i></>)

  return (<><i className="fas fa-battery-empty"></i></>)
}

export const formatearNumero = (props: any): string => {
  return new Intl.NumberFormat((props.lang || 'es-AR'), { style: (props.style || 'currency'), currency: (props.currency || 'ARS') }).format(props.valor || 0)
}

export const formatearPrecio = (props: any): string => {
  const valor = props.valor || 0;
  const formattedValue = new Intl.NumberFormat((props.lang || 'es-AR'), {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(valor);

  return `$${formattedValue}`;
};

export function formatearFecha(props: any) {
  var options = {
    year: props.year || 'numeric'
    , month: props.month || 'long'
    , day: props.day || 'numeric'
    , hour: props.hour || 'numeric'
    , minute: props.minute || 'numeric'
    , second: props.second || 'numeric'
  };
  return new Date(props.date).toLocaleDateString([], options);
}


export const tamañoImagen = (props: any): string => {
  if (props > 1500)
    return ("_xxl")
  if (props > 1200)
    return ("_xl")
  if (props > 992)
    return ("_lg")
  if (props > 768)
    return ("_md")

  return ("_xs")
}

export const tamañoImagenNuevo = (props: any): string => {
  if (props > 1300)
    return ("_xxl")
  if (props > 1000)
    return ("_xxl")
  if (props > 850)
    return ("_xxl")
  if (props > 849)
    return ("_s")

  return ("_xs")
    //faltan algunas medidas
  //1-desktop
  //1_1_1 mobile 
  //1_1
}

export const tamañoPortadaMarca = (props: any): string => {
  if (props > 1500)
    return ("_xl")
  if (props > 992)
    return ("_xs")
  if (props > 768)
    return ("_md")

  return ("_xs")
}




export const cantidadTarjetas = (): number => {
  const anchoPantalla = window.innerWidth;

  if (anchoPantalla >= 1500)
    return 6;
  if (anchoPantalla >= 1200)
    return 5;
  if (anchoPantalla >= 992)
    return 4;
  if (anchoPantalla >= 768)
    return 3;

  return 2;
}

export function isUserLogued() : boolean {
  return (store.getState().authreducer.token != null)
}



//Este procedimiento toma dos string con el formato YYYY-MM-DD y calcula la diferencia en meses entre los mismos
//Si start o end vienen vacio toma new Date().
export function monthDiffBetweenDates(startDate: string , endDate :string) : number {
    
  //Tomo la fecha de alta pero como es texto entonces tengo que partirla por los - para obtener YYYY MM DD
    const startDateParts = (startDate)?.split("-");
    let start = new Date(); //inicializo solo por si el split no corta en 3
    if (startDateParts?.length === 3) { //Si tiene 3 partes entonces responde a YYYY-MM-DD
      const year = parseInt(startDateParts[0], 10);
      const month = parseInt(startDateParts[1], 10) - 1;
      const day = parseInt(startDateParts[2], 10);
      // Creo la fecha como Date con los valores extraidos del texto
      start = new Date(year, month, day);
    } 
 
    
    const endDateParts = (endDate)?.split("-");
    let end = new Date();//inicializo solo por si el split no corta en 3
    if (endDateParts?.length === 3) { //Si tiene 3 partes entonces responde a YYYY-MM-DD
      const year = parseInt(endDateParts[0], 10);
      const month = parseInt(endDateParts[1], 10) - 1;
      const day = parseInt(endDateParts[2], 10);
      // Creo la fecha como Date con los valores extraidos del texto
      end = new Date(year, month, day);

    } 
    // Retorno la diferencia entre las fechas
    return (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth();
}

export function getProductBadgeInfo(articulo: any) {
  //Declaro las variables a devolver
  let badgeBg = '';
  let badgeText = '';
  let badgeClassName = '';

  //Si no me pasan el articulo entonces vulvo vacio
  if (articulo == null || articulo == undefined) {
    return { badgeBg, badgeText, badgeClassName };
  }

  //Usando el procedimiento me fijo si la fecha de alta es en los ultimos 3 meses
  if (monthDiffBetweenDates(articulo.articuloFechaAlta,"") <= 3) {
    badgeBg = 'bg-nuevo';
    badgeText = 'Nuevo producto';
    badgeClassName =
      'badge bg-orange tx-12 font-weight-semibold text-primiary ms-1 me-1 wd-100p';
    return { badgeBg, badgeText, badgeClassName };
  }

  // const lastDigit = articulo.articuloCodigo % 10;

  // if (lastDigit >= 1 && lastDigit <= 5) {
  //   badgeBg = 'bg-oferta';
  //   badgeText = 'Oferta';
  //   badgeClassName =
  //     'badge-success tx-12 font-weight-semibold text-primiary ms-1 me-1 wd-100p';
  // } else if (lastDigit === 0) {
  //   badgeBg = 'bg-liquidacion';
  //   badgeText = 'Liquidacion';
  //   badgeClassName =
  //     'badge-danger tx-12 font-weight-semibold text-primiary ms-1 me-1 wd-100p';
  // }

  //Si no salio por algun otro caso, entonces retorno 
  return { badgeBg, badgeText, badgeClassName };
}





