import { Col, Row } from "react-bootstrap";
import { useEffect } from "react";
import CardMarca from "../Card Marca/CardMarca";
import { useSelector, useDispatch } from "react-redux";
import { decryptData } from "../../functions/cryptoUtils";
import APIFc from "../../services/APIFc";
import { setMarcas } from "../../redux/actions/principal";
import FcCarousel from "../FcCarousel/FcCarousel";

export default function ShowMarcasInicio() {
  const marcas = useSelector((store: any) => decryptData(store.principalreducer.marcas) || []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!marcas || marcas?.length === 0) {
      APIFc.get(`/web/articulos/marcas`).then(res => {
        dispatch(setMarcas(res.data));
      });
    }
  }, [dispatch, marcas]);

  return (
    <div>
      <div className='mg-b-10 mg-t-30'>
        <h1 className=" display-6 tx-20 tx-bold"><strong>Marcas</strong></h1>
      </div>
      <Row>
        {(marcas && marcas.length) ? (
          <FcCarousel items={
            marcas.sort(() => 0.5 - Math.random()).slice(0, 8).map((marca: any) => (
              <Col xs={6} sm={6} xl={3} xxl={3} lg={4} md={4} className="mg-t-10" key={marca.id}>
                <CardMarca marca={marca} />
              </Col>
            ))
          } />
        ) : (
          <></>
        )}
      </Row>
    </div>
  );
}
