import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import apiFc from '../../../services/APIFc';
import { decryptData } from '../../../functions/cryptoUtils';
import CardMarca from '../../Card Marca/CardMarca';
import { SelectOption } from '../../common/Interfaces';

const MarcaListado = () => {
    const navigate = useNavigate();
    const user = useSelector((store: any) => decryptData(store.authreducer.user));
    const [queryParameters] = useSearchParams();
    const [items, setItems] = useState([]);
    const [filtroLocal, setFiltroLocal] = useState('');
    const [selectedGroup, setSelectedGroup] = useState<any>({});
    const [articulosGrupo, setArticulosGrupo] = useState([]);
    const [showDetalle, setShowDetalle] = useState(false);
    const [marcaId, setMarcaId] = useState(queryParameters.get("marcaId"));
    const marcas = useSelector((store: any) => decryptData(store.principalreducer.marcas) || []);
    const [rubroSeleccionado, setRubroSeleccionado] = useState<SelectOption>();
    const [lineaSeleccionada, setLineaSeleccionada] = useState<SelectOption>();
    const [pageSize, setPageSize] = useState(8);
    // const marcasSinArticulos = marcas.filter((marca:any) => 
    //     !marca.articuloListSample || marca.articuloListSample.length === 0 ||  marca.articuloListSample.length === 2 || marca.articuloListSample.length === 1
    //   );
      
    //   console.log(marcasSinArticulos);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setMarcaId(queryParameters.get("marcaId"));
        fetchData();
    }, [queryParameters]);

    useEffect(() => {
        if (selectedGroup.articuloGrupoId > 0) {
            try {
                apiFc.get(`/articuloGrupos/${selectedGroup.articuloGrupoId}/articulos`).then(res => {
                    setArticulosGrupo(res.data);
                    setShowDetalle(true);
                });
            } catch (error) { }
        }
    }, [selectedGroup]);

    const fetchData = () => {
        if (marcaId !== null) {
            apiFc.get(`/web/articulos/marcas/${marcaId}/articulogrupos?page=0&rows=1000`)
                .then(res => {
                    setItems(res.data.content);
                    setFiltroLocal('');
                    window.scrollTo(0, 0);
                });
        }
    };

    useEffect(() => {}, [filtroLocal]);

    useEffect(() => {
        fetchData();
        setLineaSeleccionada(undefined);
    }, [lineaSeleccionada && rubroSeleccionado]);

    const handlePageSizeChange = (selectedOption: any) => {
        setPageSize(selectedOption.value);
    };

    const pageSizeOptions = [
        { value: 8, label: 'Mostrar 8' },
        { value: 16, label: 'Mostrar 16' },
        { value: marcas.length, label: 'Mostrar todas' }
    ];

    return (
        <div>
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
                    <span className="main-content-title mg-b-0 mg-b-lg-1">
                        Listado Marcas
                    </span>
                </div>
            </div>
            <Row className="row-sm mg-t-10">
                <Card className='wd-100p'>
                    <Card.Body className="p-2">
                        <Row className="align-items-center">
                            <Col md={10}>
                                <div className="input-group">
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        placeholder="Buscar ..."
                                        onChange={(e: any) => setFiltroLocal(e.target.value)}
                                    />
                                    <span className="input-group-append">
                                        <Button variant="" className="btn btn-primary" type="button">
                                            Buscar
                                        </Button>
                                    </span>
                                </div>
                            </Col>
                            <Col md={2}>
                                <Select
                                    className=" paso11"
                                    defaultValue={pageSizeOptions[0]}
                                    options={pageSizeOptions}
                                    onChange={handlePageSizeChange}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Col xl={12} lg={12} md={12}>
                    <Row className="row-sm justify-content-center">
                        <Row className="wd-100p">
                            {marcas.length === 0 ? (
                                <div className="text-center mg-b-20">
                                    <Spinner animation="border" className="spinner-border" role="status">
                                        <span className="sr-only">Cargando...</span>
                                    </Spinner>
                                </div>
                            ) : (
                                <Row className="wd-100p">
                                    {marcas && marcas.length
                                        ? marcas
                                            .filter((marca: any) => filtroLocal === '' || marca.descripcion.toLowerCase().includes(filtroLocal.toLowerCase()))
                                            .slice(0, pageSize)
                                            .map((marca: any) => (
                                                <Col xs={6} sm={6} xl={3} xxl={3} lg={4} md={4} className="mg-t-10" key={marca.id}>
                                                    <CardMarca marca={marca} />
                                                </Col>
                                            ))
                                        : <></>
                                    }
                                </Row>
                            )}
                        </Row>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default MarcaListado;
