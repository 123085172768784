const INIT_STATE: any = {
    comprobantes: "",
};

export const resumenreducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case 'FILTER_GRID_COMPROBANTE':
            return { ...state, comprobantes: action?.payload};
        default:
            return state;
    }
};
