import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import apiFc from "../../services/APIFc";
import LazyImage from "../../functions/LazyImage";
import FcCarousel from "../FcCarousel/FcCarousel";
import CardArticulo from "../Card Inicio/CardArticulo";
import CardMarca from "../Card Marca/CardMarca";
import { useSelector } from "react-redux";
import { decryptData } from "../../functions/cryptoUtils";
import CardLinea from "../CardLinea/CardLinea";
import CardRubros from "../Card Rubros/CardRubros";


export default function ShowRubroInicio() {
  const rubros = useSelector((store: any) => decryptData(store.principalreducer.rubros) || []);


  return (
    <div >
      <div className='mg-b-10 mg-t-30'>
        <h1 className=" display-6 tx-20 tx-bold"><strong>Rubro</strong></h1>
      </div>
      {(rubros) ?
        <>
          <Row className="Wd-100p">
            <FcCarousel items={rubros?.map((rubro: any, i: any) => (
              i < 3 ? (
                <Col xs={12} sm={12} xl={12} xxl={12} lg={12} md={12} key={rubro.id}>
                  <CardRubros rubro={rubro} />
                </Col>
              ) : <></>
            ))} />
          </Row>
        </>
        : <></>
      }
    </div>
  )
}

