import React from "react";
import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import apiFc from "../../services/APIFc";
import { AlertDialogInterface } from "./Interfaces";
import { useForm, SubmitHandler } from "react-hook-form";


type ErrorPedido = {
    pedidoErrorNroMovVenta: string;
    errorTipo: { pedidoErrorTipoID: string };
    pedidoErrorObservacionCarga: string;
    pedidoErrorList?: any
    pedidoErrores: { pedidoErrorEstado: number };
};

export default function DetalleError(props: any) {
    const [showDetalleError, setShowDetalleError] = useState<boolean>(props.visible);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [errorTipo, setErrorTipo] = useState([]);
    const [pedidoErrores, setPedidoErrores] = useState<any>({});
    //Administrador de las tabs
    const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
        id: "1",
        visible: false,
        severity: 'success', // severity="error" / "warning" / "info" / "success"
        timeOut: 2000, // severity="error" / "warning" / "info" / "success"
        cabecera: "Enviado!",
        texto: "su formulario fue enviado y esta siendo revisado.",
    });
    useEffect(() => {
    }, [alertDialog]);

    useEffect(() => {
        apiFc.get(`/app/vendedor/sincro/datos/pedidoErrorTipo?hash=control_ip$5`)
            .then(res => {
                setErrorTipo(res.data)
            }).catch(err => {
                setErrorTipo([])
            });
    }, []);

    useEffect(() => {
        apiFc.get(`/pedidoErrores/1777`)
            .then(res => {
                setPedidoErrores(res.data)
            }).catch(err => {
                setPedidoErrores([])
            });
    }, []);

    useEffect(() => {
        if (showDetalleError) {
            setFullscreen("true")
        } else {
            props.onHandleShow();
        }
    }, [showDetalleError])



    useEffect(() => {
    }, [errorTipo]);

    useEffect(() => {
    }, [refresh]);



    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<ErrorPedido>();

    function Mensaje() {
        const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
            props,
            ref,
        ) {
            return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
        });
        const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            setAlertDialog({ ...alertDialog, visible: false });
        };
        return (
            <Snackbar
                open={alertDialog.visible}
                autoHideDuration={alertDialog.timeOut}
                onClose={handleClose}
                //Aca decido si es mobile muestro arriba el cartelito, sino abajo
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose}
                    severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
                    sx={{ width: '100%' }}>
                    <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
                </Alert>
            </Snackbar>
        )
    }

    const onSubmit: SubmitHandler<ErrorPedido> = async (data) => {
        // async request which may result error
        try {
            await apiFc.post(`/pedidoErrores/web`, JSON.stringify(data))
                .then(res => {
                }).catch(res => {
                    setAlertDialog({ ...alertDialog, visible: true, severity: 'warning', cabecera: "Error conexion", texto: " intente luego" });
                });
        } catch (e) {
        
        }
    };

    let [fullScreen, setFullscreen] = useState("true");

    useEffect(() => {
    }, [fullScreen])

    function handleErrorTipoChange(value: string): void {
        errorTipo.map((errotTip: any) => {
            if (errotTip.pedidoErrorTipoID == value) {
                setValue("errorTipo", { pedidoErrorTipoID: value })
                setRefresh(!refresh)
            }
        })
    }

    const shouldShowInput = props.pedidoErrorList === 0;


    function renderCuerpo(): any {
        return (
            <React.Fragment>
                {alertDialog.visible && <Mensaje ></Mensaje>}
                <div className="card-sigin">
                    {/* <!-- Demo content--> */}
                    <div className="main-card-signin d-md-flex">
                        <div className="wd-100p">
                            <div className="d-flex mb-4">
                            </div>
                            <div className="">
                                <div className="main-signup-header">
                                    <div className="panel panel-primary">
                                        <div className=" tab-menu-heading mb-2 border-bottom-0">
                                            <div className="tabs-menu1">
                                                <Form onSubmit={handleSubmit(onSubmit)}>
                                                    <Form.Group >
                                                        {/* <Form.Label>Pedido</Form.Label> */}
                                                        <Form.Label>Numero Pedido (NMV)</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={pedidoErrores.pedidoErrorNroMovVenta}
                                                            readOnly
                                                            {...register("pedidoErrorNroMovVenta")}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group >
                                                        {/* <Form.Label>Pedido</Form.Label> */}
                                                        <Form.Label>Cliente codigo</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={pedidoErrores.cliente}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group >
                                                        {/* <Form.Label>Pedido</Form.Label> */}
                                                        <Form.Label>Fecha </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={pedidoErrores.pedidoErrorFechaCarga && new Date(pedidoErrores.pedidoErrorFechaCarga).toLocaleDateString([])}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group >
                                                        {/* <Form.Label>Pedido</Form.Label> */}
                                                        <Form.Label>Observacion carga </Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            type="text"
                                                            value={pedidoErrores.pedidoErrorObservacionCarga}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    {shouldShowInput && (
                                                        <Form.Group >
                                                            <Form.Label>Tipo Pedido</Form.Label>
                                                            <select
                                                                className="form-control"
                                                                value={getValues("errorTipo.pedidoErrorTipoID")}
                                                                onChange={(e) => (handleErrorTipoChange(e.target.value))}
                                                            >
                                                                <option value="">Seleccione</option>
                                                                {errorTipo?.map((errorTipo: any) => {
                                                                    return (
                                                                        <option value={errorTipo.pedidoErrorTipoID}>{errorTipo.pedidoErrorTipoDetalle}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                            {errors.errorTipo && (
                                                                <Form.Text className="text-danger">
                                                                    Este campo es requerido
                                                                </Form.Text>
                                                            )}
                                                        </Form.Group>
                                                    )}
                                                    {/* {shouldShowInput && ( */}
                                                    <Form.Group >
                                                        <Form.Label>Tipo Pedido</Form.Label>
                                                        <select
                                                            className="form-control"
                                                            value={getValues("errorTipo.pedidoErrorTipoID")}
                                                            onChange={(e) => (handleErrorTipoChange(e.target.value))}
                                                        >
                                                            <option value="">Seleccione</option>
                                                            {errorTipo?.map((errorTipo: any) => {
                                                                return (
                                                                    <option value={errorTipo.pedidoErrorTipoID}>{errorTipo.pedidoErrorTipoDetalle}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        {errors.errorTipo && (
                                                            <Form.Text className="text-danger">
                                                                Este campo es requerido
                                                            </Form.Text>
                                                        )}
                                                    </Form.Group>
                                                    {/* )} */}
                                                    <Form.Group controlId="pedidoErrorObservacionCarga">
                                                        <Form.Label>Detalle del error</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder="Detalle"
                                                            {...register("pedidoErrorObservacionCarga")}
                                                        />
                                                    </Form.Group>
                                                    {/* <Button variant="primary" type="submit" className="mg-t-10"  >
                                                        Enviar
                                                    </Button> */}
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }



    return (
        <Modal className="wd-80p d-flex m-auto" dialogClassName="modal-80w" show={showDetalleError} onHide={() => setShowDetalleError(false)}>
            <Modal.Header>
                <Modal.Title>Informe del error</Modal.Title>
                <span className="d-flex ms-auto" onClick={() => setShowDetalleError(false)}><i className='fe fe-x ms-auto' ></i></span>
            </Modal.Header>
            <Modal.Body>
                {renderCuerpo()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDetalleError(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}