import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { lazy } from "react";
import "./App.css";
import Login from "./components/Pages/Login/Login";
import Loader from "./shade/Loaders/Loaders";
import Error404 from "./components/Pages/Authentication/404Error/404Error";
import PuestoDeVenta from "./components/Pages/PuestoDeVenta/PuestoDeVenta";
import Catalogo from "./components/Pages/Articulos/Catalogo/Catalogo";
import ArticuloNovedades from "./components/Pages/Articulos/ArticuloNovedades/ArticuloNovedades";
import MiPedidoActual from "./components/Pages/PedidosDevoluciones/MiPedidoActual/MiPedidoActual";
import HistoricoPedido from "./components/Pages/PedidosDevoluciones/HistoricoPedido/HistoricoPedido";
import MisComprobantes from "./components/Pages/CuentaCorriente/MisComprobantes";
import Descargas from "./components/Pages/Descargas/Descargas";
import Inicio from "./components/Pages/Inicio/Inicio";
import Novedades from "./components/Pages/Novedades/Novedades";
import ReactFacebookPixel from "react-facebook-pixel";
import Ofertas from "./components/Pages/Articulos/Ofertas/Ofertas";
import GrupoArticulos from "./components/Pages/Articulos/GrupoArticulos/GrupoArticulos";
import { logOut, setVersion, setUserInfo, setLoginText} from "./redux/actions/auth";
import ErrorPedidos from "./components/Pages/PedidosDevoluciones/ErrorPedidos/ErrorPedidos";
import CatalogoListado from "./components/Pages/Articulos/Catalogo/CatalogoListado";
import CatalogoRubro from "./components/Pages/CatalogoRubro/CatalogoRubro";
import ExternalApp from "./shade/External/Layouts/ExternalApp";
import MarcaListado from "./components/Pages/MarcaListado/MarcaListado";

//import DatosEmpresa from "./components/Pages/DatosEmpresa/DatosEmpresa";
import { decryptData } from "./functions/cryptoUtils";
import apiFc from "./services/APIFc";
import Reporte from "./components/Pages/Reporte/Reporte";
import CatalogoMarca from "./components/Pages/CatalogoMarca/CatalogoMarca";
import CatalogoPrincial from "./components/Pages/CatalogoPrincipal/CatalogoPrincipal";

// import MisDatos from './components/Pages/MisDatos';
const AppLayout = lazy(() => import("../src/shade/layouts/App"));
const MisDatos = lazy(() => import("./components/Pages/MiCuenta/MisDatos"));

function App() {
  //const { token, version } = useSelector((store) => store.authreducer);
  const token = useSelector((store) => decryptData(store.authreducer.token));
  const version = useSelector((store) =>
    decryptData(store.authreducer.version)
  );
  const navigate = useNavigate();

  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  ReactFacebookPixel.init("2778626159068368", options);
  const dispatch = useDispatch();

  useEffect(() => {
    let path = `${process.env.PUBLIC_URL}/`;
    if (version !== 1) {
      dispatch(logOut("Sesión finalizada, lo esperamos proximamente"));
      dispatch(setVersion(1));
    }
    if (!token) {
      navigate(path);
    }
    // hacer la búsqueda del usuario después de la verificación
    apiFc
      .get(`web/cliente`)
      .then((resCliente) => {
        const userData = resCliente.data;
        const fechaAccesoExpirada = new Date(userData.clienteAccesoHastaFecha);
        const hoy = new Date();
        const fechaAccesoExpirada2 = fechaAccesoExpirada >= hoy;
        // chequear si el acceso está habilitado
        if (!userData.clienteHabilitado) {
          dispatch(
            setLoginText(
              "Acceso no permitido: Cuenta deshabilitada. Comuniquese con su vendedor para habilitarla nuevamente."
            )
          );
          return;
        }

        // chequear si la fecha de acceso es mayor o igual a hoy
        if (!fechaAccesoExpirada2) {
          dispatch(
            setLoginText(
              "Acceso no permitido: Fecha de acceso no válida. Comuniquese con su vendedor para habilitarla nuevamente."
            )
          );
          return;
        }

        // Acceso permitido
        dispatch(setUserInfo(userData));
        // Si no tiene explícitamente seteado que debe ser vertical, entonces seteo horizontal
        if (localStorage.getItem("nowavertical") !== "true") {
          localStorage.setItem("nowavertical", "false");
          localStorage.setItem("nowahorizontal", "true");
          localStorage.removeItem("nowahorizontalHover");
        }
        path = `${process.env.PUBLIC_URL}/principal/inicio`;
        navigate(path);
      })
      .catch((res) => {
        dispatch(
          setLoginText("Error consultando cliente en db, intente nuevamente.")
        );
      });
  }, [token]);

  return (
    <React.Fragment>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/*`} element={<Error404 />} />
          {/* login */}

          {/* <Route path={`${process.env.PUBLIC_URL}/`} element={<Login />}>
            </Route> */}
          <Route path={`${process.env.PUBLIC_URL}/`} element={<ExternalApp />}>
            <Route index element={<Inicio />} />
            <Route
              path={`${process.env.PUBLIC_URL}/catalogolistado`}
              element={<CatalogoListado />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/catalogorubro`}
              element={<CatalogoRubro />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/novedades`}
              element={<Novedades />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/marcalistado`}
              element={<MarcaListado />}
            />
              <Route
              path={`${process.env.PUBLIC_URL}/catalogomarca`}
              element={<CatalogoMarca />}
            />
            {/* <Route
              path={`${process.env.PUBLIC_URL}/datosempresa`}
              element={<DatosEmpresa />}
            /> */}
          </Route>

          <Route
            path={`${process.env.PUBLIC_URL}/login`}
            element={<Login />}
          ></Route>
          {/*Main Menu  */}
          <Route
            path={`${process.env.PUBLIC_URL}/principal`}
            element={<AppLayout />}
          >
            <Route
              path={`${process.env.PUBLIC_URL}/principal/novedades`}
              element={<Novedades />}
            />
            {/* Mi cuenta */}
            <Route
              path={`${process.env.PUBLIC_URL}/principal/inicio`}
              element={<Inicio />}
            />
            {/* Mi cuenta */}
            <Route
              path={`${process.env.PUBLIC_URL}/principal/micuenta/misdatos`}
              element={<MisDatos />}
            />
            {/* Articulos */}
            <Route
              path={`${process.env.PUBLIC_URL}/principal/pdv`}
              element={<PuestoDeVenta />}
            />
            <Route path={`${process.env.PUBLIC_URL}/principal/articulos/ofertas/:id`} element={<Ofertas />} />
            <Route
              path={`${process.env.PUBLIC_URL}/principal/articulos/ofertas`}
              element={<Ofertas />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/principal/articulos/novedades`}
              element={<ArticuloNovedades />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/principal/articulos/catalogo`}
              element={<Catalogo />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/principal/articulos/grupo`}
              element={<GrupoArticulos />}
            />
            {/* pedidos/devoluciones */}
            <Route
              path={`${process.env.PUBLIC_URL}/principal/pedidos/mipedidoactual`}
              element={<MiPedidoActual />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/principal/pedidos/historicopedido`}
              element={<HistoricoPedido />}
            />
            {/* Cuenta corriente */}
            <Route
              path={`${process.env.PUBLIC_URL}/principal/miscomprobantes`}
              element={<MisComprobantes />}
            />
            {/* descargas */}
            <Route
              path={`${process.env.PUBLIC_URL}/principal/descargas`}
              element={<Descargas />}
            />
            {/* error pedidos */}
            <Route
              path={`${process.env.PUBLIC_URL}/principal/errorpedidos`}
              element={<ErrorPedidos />}
            />
            {/* catalogo listado */}
            <Route
              path={`${process.env.PUBLIC_URL}/principal/catalogolistado`}
              element={<CatalogoListado />}
            />
            {/* catalogo rubro */}
            <Route
              path={`${process.env.PUBLIC_URL}/principal/catalogorubro`}
              element={<CatalogoRubro />}
            />
            {/* reporte*/}
            <Route
              path={`${process.env.PUBLIC_URL}/principal/reporte`}
              element={<Reporte />}
            />
              <Route
              path={`${process.env.PUBLIC_URL}/principal/marcalistado`}
              element={<MarcaListado />}
            />
                <Route
              path={`${process.env.PUBLIC_URL}/principal/catalogomarca`}
              element={<CatalogoMarca />}
            />
                <Route
              path={`${process.env.PUBLIC_URL}/principal/catalogoprincipal`}
              element={<CatalogoPrincial />}
            />
          </Route>
        </Routes>
      </React.Suspense>
    </React.Fragment>
  );
}

export default App;
