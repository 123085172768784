import { AnyAction } from "redux";
import apiFc from "../../services/APIFc";
import { encryptData } from "../../functions/cryptoUtils";

export const filterProduct = (filter: string) => {
    return {
        type: "FILTER_GRID_PEDIDOS_ARTICULOS_BUSQUEDA",
        payload: encryptData(filter)
    }
}
/* Intento de redux con asynctask hay que ver por que no se pueden devolver promesas al dispatch
export const filterProductGroup = async (groupId: string) => {
    if (!groupId) {
        return {
            type: "FILTER_GRID_PEDIDOS_ARTICULOS_BUSQUEDA",
            payload: []
        }
    }
    await apiFc.get(`/articuloGrupos/${groupId}/articulos`)
        .then(res => {
            return {
                type: "FILTER_GRID_PEDIDOS_ARTICULOS_BUSQUEDA",
                payload: res.data
            }
        }).catch(err => {
            return {
                type: "FILTER_GRID_PEDIDOS_ARTICULOS_BUSQUEDA",
                payload: []
            }
        })
    return {
        type: "FILTER_GRID_PEDIDOS_ARTICULOS_BUSQUEDA",
        payload: []
    }
}*/

export const consultaArticuloHeader = (codArticulo: string) => {
    return {
        type: "FILTER_HEADER_SEARCH",
        payload: encryptData(codArticulo)
    }
}

export const consultaProducto = (data: any) => {
    return {
        type: "FILTER_GRID_PEDIDOS_ARTICULOS_BUSQUEDA",
        payload: encryptData(data)
    }
}
export const errorPedido = (detalleErrorPedido: any) => {
    return {
        type: "ERROR_PEDIDO",
        payload: encryptData(detalleErrorPedido)
    }
}
