import React, { useRef, useState } from 'react';
import './fccarousel.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Col } from 'react-bootstrap';

const FcCarousel = (props : any) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);
  const [touchStart, setTouchStart] = useState(0);
  
  const handleTouchStart = (e:any) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const goToNextSlide = () => {
    setCurrentIndex(currentIndex + 25);
  };

  const goToPrevSlide = () => {
    setCurrentIndex(currentIndex - 25);
  };

  const handleTouchMove = (e:any) => {
    const touchMove = e.targetTouches[0].clientX;
    const touchDifference = touchStart - touchMove;
    if (Math.abs(touchDifference) > 20) {
      if (touchDifference > 0) {
        goToNextSlide();
      } else {
        goToPrevSlide();
      }
      setTouchStart(touchMove);
    }
  };

  return (
    <div 
      className="fc-carrusel-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      ref={containerRef}
    >
      {currentIndex !== 0 && (
        <div className="prev-button" onClick={goToPrevSlide}>
          <ArrowBackIosNewIcon className="fc-carousel-btn-icon"></ArrowBackIosNewIcon>
        </div>
      )}
      <div 
        className="fc-carrusel-item" 
        style={{ width: '100%', transform: `translateX(-${currentIndex}%)` }} 
      >
        {props.items.map((item: any, index: number) => (
          <React.Fragment key={index}>
            {item}
          </React.Fragment>
        ))}
      </div>
      {currentIndex < 100 && (
        <div className="next-button" onClick={goToNextSlide}>
          <ArrowForwardIosIcon className="fc-carousel-btn-icon"></ArrowForwardIosIcon>
        </div>
      )}
    </div>
  );
};

export default FcCarousel;


// import React, { useRef, useState, useEffect } from 'react';
// import './fccarousel.css';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// const FcCarousel = (props: { items: any[] }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const containerRef = useRef<HTMLDivElement | null>(null); 
//   const [touchStart, setTouchStart] = useState(0);
//   const [stepSize, setStepSize] = useState(0);

//   useEffect(() => {
//     if (containerRef.current && containerRef.current instanceof HTMLElement) {
//       const containerWidth = containerRef.current.getBoundingClientRect().width;
//       const itemsToShow = 1; // desplaza una vez
//       const calculatedStepSize = containerWidth / itemsToShow;
//       setStepSize(calculatedStepSize);
//     }
//   }, [props.items]); 


//   const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
//     setTouchStart(e.targetTouches[0].clientX);
//   };

//   const goToNextSlide = () => {
//     setCurrentIndex(currentIndex + stepSize);
//   };

//   const goToPrevSlide = () => {
//     setCurrentIndex(currentIndex - stepSize);
//   };

//   const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
//     const touchMove = e.targetTouches[0].clientX;
//     const touchDifference = touchStart - touchMove;
//     if (Math.abs(touchDifference) > 20) {
//       if (touchDifference > 0) {
//         goToNextSlide();
//       } else {
//         goToPrevSlide();
//       }
//       setTouchStart(touchMove);
//     }
//   };

//   return (
//     <div
//       className="fc-carrusel-container"
//       onTouchStart={handleTouchStart}
//       onTouchMove={handleTouchMove}
//       ref={containerRef}
//     >
//       {currentIndex !== 0 && (
//         <div className="prev-button" onClick={goToPrevSlide}>
//           <ArrowBackIosNewIcon className="fc-carousel-btn-icon" />
//         </div>
//       )}
//       <div className="fc-carrusel-item" style={{ width: `100%`, transform: `translateX(-${currentIndex}px)` }}>
//         {props.items.map((item, index) => item)}
//       </div>
//       {currentIndex < props.items.length * stepSize && (
//         <div className="next-button" onClick={goToNextSlide}>
//           <ArrowForwardIosIcon className="fc-carousel-btn-icon" />
//         </div>
//       )}
//     </div>
//   );
// };

// export default FcCarousel;