import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { decryptData } from "../../functions/cryptoUtils";
import FcCarousel from "../FcCarousel/FcCarousel";
import CardLinea from "../CardLinea/CardLinea";

interface ShowLineasInicioProps {
  id: number;
}

const ShowLineasInicio: React.FC<ShowLineasInicioProps> = ({ id }) => {
  const rubros = useSelector((store: any) => decryptData(store.principalreducer.rubros) || []);
  return (
    <div>
      <div className='mg-b-10 mg-t-30'>
        <h1 className="display-6 tx-20 tx-bold"><strong>Lineas</strong></h1>
      </div>
      <Row>
        {(rubros && rubros.length > 0) ? (
          <FcCarousel items={
            rubros.sort(() => Math.random() - 0.5).slice(0, 6).map((rubro: any) => {
              const linea = rubro.articuloRubroLineaList && rubro.articuloRubroLineaList.length > 0 ? rubro.articuloRubroLineaList[0] : null;
              return (
                <Col xs={12} sm={12} xl={4} xxl={4} lg={4} md={4} className="justify-content-center" key={rubro.id}>
                  {linea ? (
                    <CardLinea rubro={rubro} linea={linea}  showRubroName={false} showLineaName ={false} showIcon={false}/>
                  ) : (
                    <p>No hay líneas disponibles para este rubro.</p>
                  )}
                </Col>
              );
            })
          } />
        ) : (
          <p>No hay rubros disponibles.</p>
        )}
      </Row>
    </div>
  );
};

// Exportar el componente memorizado
export default React.memo(ShowLineasInicio, (prevProps, nextProps) => {
  return prevProps.id === nextProps.id;
});