import React, { useRef, useEffect } from 'react';

const LazyImage = ({ src , alt, className , onError }) => {
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Load the image when it comes into view
            imgRef.current.src = src;
            observer.unobserve(imgRef.current);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    observer.observe(imgRef.current);

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, [src]);

  return <img className= {className} ref={imgRef} alt={alt} loading="lazy" onError={onError}/>;
};

export default LazyImage;
