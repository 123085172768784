import { Badge, Card, Carousel, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import "./cardarticulo.css"
import { useDispatch, useSelector } from "react-redux";
import { consultaArticuloHeader } from "../../redux/actions/grid";
import InputCarritoCompra from "../common/InputCarritoCompra";
import { useEffect, useState } from "react";
import { Link } from "@material-ui/core";
import { getProductBadgeInfo, monthDiffBetweenDates } from "../../functions/Procedimientos";
import LazyImage from "../../functions/LazyImage";



export default function CardInicio(props: any) {

  const [alertDialog, setAlertDialog] = useState({
    id: "1",
    visible: false,
    severity: 'success', // severity="error" / "warning" / "info" / "success"
    timeOut: 2000, // severity="error" / "warning" / "info" / "success"
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });

  useEffect(() => {
    setAlertDialog({ ...alertDialog, visible: false })
    window.scrollTo(0, 0)
  }, [])

  const item = props.item

  const showCarrito = props.mostrarInputCarrito;

  const dispatch = useDispatch();

  function openModalSlider() {
    dispatch(consultaArticuloHeader(item?.articuloCodigo))
  };


  function articuloImagen(): string {
    return item?.articuloImagenV2 || item?.articuloImagenGXI || item?.articuloGrupoId?.articuloGrupoImagenGXI || ""
  };

  function articuloRubro(): string {
    return item?.rubro?.detalle || "Sin rubro"
  };

  function articuloLinea(): string {

    return item?.articuloRubroLinea?.detalle || "Sin linea"
  };

  

  // const { badgeBg, badgeText, badgeClassName } = getProductBadgeInfo(item);

  return (

    <div className="card" id={item?.articuloCodigo}>
      <div className="cardcontainer" onClick={openModalSlider}>
        <div className="picture-container ">
          <div className="badge-container">
            <ul className="icons">
              <li >
                <Link >
                  {/* <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{badgeText}</Tooltip>}
                  >
                    {(badgeText != '') ?
                      <div className="primary-gradient me-2"  >
                        <Badge bg={badgeBg} className={badgeClassName}>
                          <dt>{badgeText}</dt>
                        </Badge>
                      </div>
                      : <></>
                    }
                  </OverlayTrigger> */}

                </Link>
              </li>
            </ul>
          </div>
          <LazyImage
            className="picture"
            key={item?.articuloCodigo}
            src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articuloImagen()}`}
            alt="Lazy-loaded Image"
            onError={(e: any) => {
              e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
            }} />
        </div>
        <div className="cardbody cardcontent">
          <div className="category">
            <small><strong>{articuloRubro()}</strong></small>
          </div>
          <div className="line">
            <small>{articuloLinea()}</small>
          </div>
          <div className="title">
            {item.articuloDescripcion}
          </div>
          <div className="btn-compra">
            {showCarrito ? (
              <InputCarritoCompra
                articulo={item?.articuloCodigo}
                origen="catalogo"
                onEvent={(e: any) => setAlertDialog(e)}
              />
            ) : <></>}
          </div>
        </div>
      </div>
    </div>
  )
}