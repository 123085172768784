import React from 'react';
import { useNavigate } from 'react-router-dom';
import './circlearticle.css'; 


interface MarcaLogoProps {
    marca: {
        id: any;
        descripcion: any;
    };
    onClick?: () => void; // Define the onClick prop as a function
}


const MarcaLogo: React.FC<MarcaLogoProps> = (props) => {
    const navigate = useNavigate();
    const customOnClick =  props.onClick == undefined 
    ? () => navigate(`/principal/catalogomarca?marcaId=${props.marca.id}`)
    : props.onClick

    return (
        <>
            {props.marca === undefined ? (
                <></>
            ) : (
                <div className="marca-logo-container" onClick={customOnClick}>
                    <div className="marca-logo-details">
                        <div>
                            <div className="circle-container logo-overlay mg-b-5 color-background">
                                <img src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Marcas/${props.marca.id}.jpg`} alt="logo.png" className="circle-logo-img" />
                            </div>
                        <div className="text-style-container">
                            <p className="text-style-2 d-flex justify-content-center">{props.marca.descripcion}</p>
                        </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};


export default MarcaLogo;
