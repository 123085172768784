import React, { Fragment, useEffect, useState } from "react";
import ExternalHeader from "../Header/ExternalHeader";
import ExternalSidebar from "../Sidebar/ExternalSidebar";
import Footer from "../../Footer/Footer";
import { Outlet } from "react-router-dom";
import TabToTop from "../../TabToTop/TabToTop";
import { Provider, useDispatch, useSelector } from "react-redux";
import Store from "../../../redux/store/store"
import Loader from "../../Loaders/Loaders";
import DetalleArticulo from "../../../components/common/DetalleArticulo";
import { consultaArticuloHeader } from "../../../redux/actions/grid";
import CartelInformativo from "../../../components/common/CartelInformativo";
import Switcher from "../../Switcher/Switcher";
import { decryptData } from "../../../functions/cryptoUtils";

//import RightSidebar from "../RightSidebar/RightSidebar";

export default function ExternalApp() {
  //The created store
  document.querySelector("body")?.classList.add("ltr", "main-body", "app", "sidebar-mini", "sidenav-toggled");
  document.querySelector("body")?.classList.remove("error-page1", "bg-primary");

  const responsiveSidebarclose = () => {
    //leftsidemenu
    //document.querySelector(".app")?.classList.remove("sidenav-toggled");
    //rightsidebar
    //document.querySelector("body")?.classList.toggle("sidenav-toggled");
    //document.querySelector(".sidebar-right")?.classList.remove("sidebar-open");
    //swichermainright
    // document.querySelector(".demo_changer")?.classList.remove("active");
    // let Rightside: any = document.querySelector(".demo_changer")
    // Rightside.style.right = "-270px";
  };

  function Sidebargone(gone: any) {
    if (gone.matches) { // Si gone  que es la variable de 1024 px o menos es verdadera  
      //entonces cierro el menu por que se va a mostrar el boton de Abrir y cerrar menu
      document.querySelector("body")?.classList.toggle("sidenav-toggled");
      document.querySelector("main-content horizontal-content")?.classList.remove("horizontal-content");
    } else {
      //si la pantalla es mayor a 1024 entoncs dejo el menui mostrandose
      document.querySelector("body")?.classList.remove("sidebar-gone");
      if (localStorage.getItem("nowahorizontal") === 'true' || localStorage.getItem("nowahorizontal") == null) { // Si el menu es horizontal estonces lo dejo completo 
        document.querySelector("body")?.classList.remove("sidenav-toggled");
        document.querySelector(".app")?.classList.remove("fixed-layout");
        document.querySelector(".app")?.classList.add("scrollable-layout");
      }
    }
  }

  var gone = window.matchMedia("(max-width: 1024px)")
  Sidebargone(gone) // Call listener function at run time

  gone.addListener(() => {
    Sidebargone(gone);
  })


  const { axiosError } = useSelector((store: any) => store.commonreducer);
  const  articuloBuscadorHeader  = useSelector((store: any) => decryptData(store.gridreducer.articuloBuscadorHeader));
  const [showDetalleArticulo, setShowDetalleArticulo] = useState<{ visible: boolean, codArticulo: string, fullScreen: string }>();
  const dispatch = useDispatch();



  useEffect(() => {
  }, [])

  useEffect(() => {
  }, [axiosError])


  useEffect(() => {
    if (Object.keys(articuloBuscadorHeader)?.length !== 0) {
      setShowDetalleArticulo({ ...showDetalleArticulo, visible: true, codArticulo: articuloBuscadorHeader, fullScreen: "true" })
    }
    else {
      setShowDetalleArticulo({ ...showDetalleArticulo, visible: false, codArticulo: articuloBuscadorHeader, fullScreen: "true" })
    }
  }, [articuloBuscadorHeader])

  function handleShowDetalleArticulo(articuloCodigo: string) {
    setShowDetalleArticulo({ ...showDetalleArticulo, visible: true, codArticulo: articuloCodigo, fullScreen: "true" })
  }

  return (
    <Fragment>
      {articuloBuscadorHeader && showDetalleArticulo?.visible &&
        <DetalleArticulo
          visible={showDetalleArticulo.visible}
          codArticulo={showDetalleArticulo.codArticulo}
          onHandleShow={() => {
            dispatch(consultaArticuloHeader(""));

            setShowDetalleArticulo({ ...showDetalleArticulo, visible: false });
          }}
        />
      }
      <React.Suspense fallback={<Loader />}>
        <Provider store={Store}>
          {/* Si es tablet entonces no dejo esparcio para el menu horizontal */}
          <div className={(window.matchMedia("(max-width: 1024px)").matches) ? "" :"horizontalMenucontainer"}>
            <TabToTop />
            <div className="page">
              <div className="open">
                <ExternalHeader />
                <ExternalSidebar />
              </div>
              <div className="main-content app-content" onClick={() => {
                responsiveSidebarclose();
              }}>
                <div className="side-app">
                  <div
                    className="main-container container-fluid"
                  >
                    <Outlet />
                  </div>
                </div>
              </div>
              <Switcher />
              <Footer />
            </div>
          </div>
        </Provider>
      </React.Suspense>
    </Fragment>
  );
}
