import React, { useRef } from "react";
import { Fragment, useEffect, useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Select from "react-select";
import { Button, Card, Col, Form, Modal, Row, Spinner, Pagination, Table, InputGroup } from "react-bootstrap";
import apiFc from "../../../../services/APIFc";
import { StockLevel } from "../../../../functions/Procedimientos";
import Paginador from "../../../common/Paginador";
import ReactFacebookPixel from 'react-facebook-pixel';
import { useDispatch, useSelector } from 'react-redux';
import { setPedidoActualRenglones } from "../../../../redux/actions/pedido";
import { isMobile } from 'react-device-detect';
import InputCarritoCompra from "../../../common/InputCarritoCompra";
import { AlertDialogInterface } from "../../../common/Interfaces";
import TestPaginador from "../../../common/TestPaginador";
import CardGrupo from "../../../Card Grupos/CardGrupo";
import { decryptData } from "../../../../functions/cryptoUtils";


const GrupoArticulos = () => {
  //usuario para poder dibujar la data
  const  user = useSelector((store: any) => decryptData(store.authreducer.user));

  const { pedidoActualRenglones } = useSelector((store: any) => store.pedidoreducer);
  const [cantidadArticuloCheck, setCantidadArticuloCheck] = useState<number>(0)
  const dispatch = useDispatch();

  const [fetchingData, setFetchingData] = useState(true);

  const [actualPage, setActualPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [viewRows, setViewRows] = useState<number>(20);
  const [filter, setFilter] = useState<string>(" ");

  const [grupos, setGrupos] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const [articulosGrupo, setArticulosGrupo] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0)
    obtenerGrupos()
  }, [])

  useEffect(() => {
  }, [grupos, fetchingData])

  useEffect(() => {
    if (selectedGroup.articuloGrupoId > 0) {
      try {
        apiFc.get(`/articuloGrupos/${selectedGroup.articuloGrupoId}/articulos`).then(res => {
          setArticulosGrupo(res.data)
          setShowDetalle(true)
        })
      } catch (error) { }
    }
  }, [selectedGroup])

  useEffect(() => {
    setActualPage(0)
    obtenerGrupos()
  }, [filter])

  useEffect(() => {
    obtenerGrupos()
  }, [actualPage])

  const [showDetalle, setShowDetalle] = useState(false);

  const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
    id: "1",
    visible: false,
    severity: 'success', // severity="error" / "warning" / "info" / "success"
    timeOut: 2000, // severity="error" / "warning" / "info" / "success"
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });

  function Mensaje(props: any) {
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
      props,
      ref,
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlertDialog({ ...alertDialog, visible: false });
    };
    return (
      <Snackbar
        open={alertDialog.visible}
        autoHideDuration={alertDialog.timeOut}
        onClose={handleClose}
        //Aca decido si es mobile muestro arriba el cartelito, sino abajo
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose}
          severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
          sx={{ width: '100%' }}>
          <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
        </Alert>
      </Snackbar>
    )
  }



  function extraLargeModal() {
    const handleClose = () => {
      setShowDetalle(false);
      setSelectedGroup({});
    }
    return (
      <>
        <Modal
          dialogClassName="modal-90w"
          show={showDetalle}
          aria-labelledby="example-modal-sizes-title-xl"
        >
          {alertDialog.visible && <Mensaje ></Mensaje>}
          <Modal.Header>
            <Modal.Title>{selectedGroup.articuloGrupoDescripcion}</Modal.Title>
            <Button
              onClick={handleClose}
              className="btn-close"
              variant=""
            >
              x
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="product-details table-responsive text-nowrap ">
              <Table className="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th className="text-start">Producto</th>
                    <th className="w-150">Cantidad</th>
                    {(user.clienteConfiguracionMostrarCos) ? <th>Costo</th> : ""}
                    <th>Sug 1</th>
                    <th>Sug 2</th>
                    <th>Stock</th>

                  </tr>
                </thead>
                <tbody>
                  {
                    articulosGrupo &&
                    <Fragment>
                      {
                        articulosGrupo.map((articulo: any) => {
                          return (
                            <Fragment key={articulo.articuloCodigo}>
                              <tr >
                                <td
                                // open={open}
                                //onClick={handleClose}
                                >
                                  <div className="media">
                                    <div className="card-aside-img">

                                      {(articulo.articuloImagenGXI !== null) ?
                                        <>
                                          <img className=" pic-1" src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articulo.articuloImagenGXI}`} alt="product"
                                            onError={(e: any) => {
                                              e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                                            }} />
                                        </>
                                        :
                                        (articulo.articuloGrupoId !== null && articulo.articuloGrupoId !== undefined && articulo.articuloGrupoId?.articuloImagenGXI !== null) ?
                                          <>
                                            <img className=" pic-1" src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articulo.articuloGrupoId.articuloGrupoImagenGXI}`} alt="product"
                                              onError={(e: any) => {
                                                e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                                              }} />
                                          </>
                                          :
                                          <>
                                            <img className=" pic-1" src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`} alt="product" />
                                          </>
                                      }
                                    </div>
                                    <div className="media-body">
                                      <div className="card-item-desc mt-0">
                                        <h6 className="font-weight-semibold mt-0 text-uppercase">
                                          {articulo.articuloDescripcion}
                                        </h6>
                                        <dl className="card-item-desc-1">
                                          <dt>{`Codigo:       `}</dt>
                                          <dd>{`       ${articulo.articuloCodigo}`}</dd>
                                        </dl>
                                        <dl className="card-item-desc-1">
                                          <dt>{`U.v:     `}</dt>
                                          <dd>{` ${articulo.articuloUnidadVenta}`}</dd>
                                          {/* <dd>{item.colors}</dd> */}
                                        </dl>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-webkit-center">
                                  <InputCarritoCompra
                                    articulo={articulo}
                                    origen="grupoArticulo"
                                    onEvent={(e: any) => setAlertDialog(e)}
                                  />
                                </td>
                                {(user.clienteConfiguracionMostrarCos) ?
                                  <td className="text-end text-lg text-medium font-weight-bold  tx-15">{articulo.articuloPrecio}</td>
                                  : ""
                                }
                                <td className="text-end text-lg text-medium font-weight-bold  tx-15">{(user.clienteConfiguracionMostrarIVA) ? (articulo.articuloPrecioVentaSug1 * (1 + (articulo.articuloTasaIVA / 100))).toFixed(2) : articulo.articuloPrecioVentaSug1}</td>
                                <td className="text-end text-lg text-medium font-weight-bold  tx-15">{(user.clienteConfiguracionMostrarIVA) ? (articulo.articuloPrecioVentaSug2 * (1 + (articulo.articuloTasaIVA / 100))).toFixed(2) : articulo.articuloPrecioVentaSug2}</td>
                                <td className="text-center font-weight-bold  tx-15">
                                  {<StockLevel stock={articulo.articuloStock} stockMin={articulo.articuloStockMin} stockMax={articulo.articuloStockMax}></StockLevel>}
                                </td>
                              </tr>
                            </Fragment>
                          )
                        })
                      }
                    </Fragment>
                  }
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }


  const obtenerGrupos = () => {
    try {
      apiFc.get(`/articuloGrupos/page?filter=${filter}&page=${actualPage}&rows=${viewRows}`).then(res => {
        setTotalPages(res.data.totalPages)
        setGrupos(res.data.content)
        setFetchingData(false)
      })
    } catch (error) { }
  };

  const filtrarBusqueda = (event: any) => {
    setFilter(event.target.value);
  };


  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Grupos Articulo</span>
          <>{extraLargeModal()}</>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}
      <Row className="row-sm">
        <Col xl={12} lg={12} md={12} className="mb-md-0 mb-3">
          <Card>
            <Card.Body className="p-2 d-flex align-items-center">
              <div className="input-group">
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder="Buscar ..."
                  onChange={filtrarBusqueda}
                />
                <span className="input-group-append">
                  <Button variant="" className="btn btn-primary" type="button">
                    Buscar
                  </Button>
                </span>
              </div>
              <div className="text-wrap ml-25 mr-25">
                <div>
                  <TestPaginador
                    currentPage={actualPage}
                    totalPages={totalPages}
                    pageSize={viewRows}
                    onClick={(e: any) => {
                      setActualPage(e)
                    }}
                  />
                </div>
              </div>
              <span className="input-group-append wd-125 ">
                <Select defaultValue={{
                  value: "10",
                  label: "10"
                }}
                  options={[
                    {
                      value: "10",
                      label: "10"
                    },
                    {
                      value: "20",
                      label: "20"
                    },
                    {
                      value: "40",
                      label: "40"
                    }
                  ]}
                  onChange={(data: any) => {
                    setViewRows(data.value)
                    obtenerGrupos()
                  }}
                />
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={12} lg={12} xl={12} xxl={12}   >
          <Row className="row-sm">
            {fetchingData ?
              <Spinner
                animation="border"
                variant="primary"
                className="spinner-border me-2 text-primary"
                role="status"
              ></Spinner>
              :
              grupos &&
              grupos.map((item: any, i) => {
                return (
                  // <Col sm={3} xl={3} xxl={3} lg={3} md={3} key={item.articuloGrupoId}>
                  //   <Card>
                  //     <Card.Body className="h-100  product-grid6">
                  //       <div className="pro-img-box product-image img-fluid imgDiv test1" >
                  //         <div className="test2" onClick={() => {
                  //           //send(list.articuloDescripcion);
                  //           // ProductService.getidfronShop(item.id)
                  //         }}>
                  //           <img className=" pic-1 test3" src={`../../../../../img/${item.articuloGrupoImagenGXI}`} alt="product"
                  //             onError={(e: any) => {
                  //               e.target.src = `../../../../../img/Imagenes/Grupos/noimg.png`
                  //             }} />
                  //           {/* <img className="pic-2 test3" src={`../../../../../img/${item.articuloGrupoImagenGXI}`} alt="product"
                  //             onError={(e: any) => {
                  //               e.target.src = `../../../../../img/Imagenes/Grupos/noimg.png`
                  //             }} /> */}
                  //         </div>
                  //       </div>
                  //       <div className="text-center pt-2">
                  //         <h3 className="h6 ht-50 mb-2 mt-4 font-weight-bold text-uppercase">
                  //           {item.articuloGrupoDescripcion}
                  //         </h3>
                  //         <Button variant=""
                  //           type="submit"
                  //           className="btn btn-primary btn-block"
                  //           onClick={() => {
                  //           setSelectedGroup(item)
                  //           }}>
                  //           Ver productos
                  //         </Button>
                  //       </div>
                  //     </Card.Body>
                  //   </Card>
                  // </Col>
                  <Col xs={6} sm={3} xl={2} xxl={2} lg={3} md={3} key={item.articuloGrupoId} className="mg-t-10 ht-100p">
                      <CardGrupo articuloGrupo={item}
                        onClick={() => {
                          setSelectedGroup(item)
                        }}
                      />
                  </Col>
                )
              })
            }
          </Row>
          <Card.Footer>
            <div className="text-wrap ml-25 mr-25 paginator-fix">
              <div>
                <TestPaginador
                  currentPage={actualPage}
                  totalPages={totalPages}
                  pageSize={viewRows}
                  onClick={(e: any) => {
                    setActualPage(e)
                  }}
                />
              </div>
            </div>
          </Card.Footer>
        </Col>
      </Row >
      {/* <!-- row closed --> */}
    </div >
  );
}



export default GrupoArticulos