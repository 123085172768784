import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import apiFc from "../../services/APIFc";
import CardArticulo from "../Card Inicio/CardArticulo";

export default function ProductosDestacadosInicio() {
    const [grupoSugerencias, setSugerencias] = useState([]);

    useEffect(() => {
        buscarSugerenciaRubroArticuloGrupos();
    }, []);

    function buscarSugerenciaRubroArticuloGrupos() {
        apiFc.get(`/web/principal/producto/sugerencias`).then(res => {
            setSugerencias(res.data);
        });
    }

    return (
        <div>
            <div className='mg-b-10 mg-t-30'>
                <h1 className="display-6 tx-20 tx-bold"><strong>Productos destacados</strong></h1>
            </div>
            <Row>
                {grupoSugerencias?.map((item: any, i) => {
                    return ((i > 6 && i < 13) ?
                        <Col xs={12} sm={4} xl={4} xxl={4} lg={4} md={4} key={item.articuloCodigo}>
                            <CardArticulo item={item} />
                        </Col>
                        : null
                    );
                })}
            </Row>
        </div>
    );
}