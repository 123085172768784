import { configureStore } from "@reduxjs/toolkit";
import persistedReducer from "../reducers/main";
import thunk from 'redux-thunk'
import encryptionMiddleware from "../../functions/encryptionMiddleware";

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    //En esta nueva implementacion de middleWare tenemos una implmenetacion propia que nos permitira en algun momento
    //trabajar de manera mas especifica con algun requerimiento futuro
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware().concat(encryptionMiddleware), 
}
);

export default store;
